import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

const FormControlRTL = ({ label, value, onChange, error, helperText, children, ...props }) => {
  return (
    <FormControl fullWidth sx={{ mt: 3 }} error={!!error} {...props}>
      <InputLabel sx={{ transformOrigin: 'top right', right: 28 }}>{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        label={label}
        InputProps={{
            sx: { textAlign: 'right', '& .MuiOutlinedInput-notchedOutline': { textAlign: 'right' } },
          }}
        sx={{ textAlign: 'right', '& .MuiOutlinedInput-notchedOutline': { textAlign: 'right' } }}
      >
        {children}
      </Select>
      {!!error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default FormControlRTL;
