import React from 'react';
import TextPage from '../TextPage/TextPage';

// דף אודות
const aboutContent = {
  title: "אודות פיתגו",
  introduction: "ברוכים הבאים ל-פיתגו, שירות לימוד חשבון מקוון לתלמידי בתי הספר. כאן תוכל ללמוד עוד עלינו ועל השירותים שאנו מציעים.",
  topics: [
    {
      title: "החזון שלנו",
      subtopics: [
        {
          title: "",
          text: "אנו שואפים להנגיש את הלימוד האישי לכל תלמיד באמצעות מערכת מקוונת המספקת תמיכה מותאמת אישית וזולה יותר מהוראה פרטית מסורתית."
        }
      ]
    },
    {
      title: "השירותים שלנו",
      subtopics: [
        {
          title: "",
          text: "שירותינו כוללים פתרון בעיות חשבון, בדיקת פתרונות אוטומטית, וסיוע בלמידה והבנה של נושאים מתמטיים מגוונים."
        }
      ]
    },
    {
      title: "המערכת שלנו",
      subtopics: [
        {
          title: "",
          text: "המערכת שלנו משתמשת בטכנולוגיות מתקדמות כדי לספק חוויית לימוד אינטראקטיבית ותומכת, המותאמת לצרכים האישיים של כל תלמיד."
        }
      ]
    },
    {
      title: "יצירת קשר",
      subtopics: [
        {
          title: "",
          text: "אם יש לכם שאלות או הצעות, אתם מוזמנים ליצור איתנו קשר בכתובת הדוא\"ל: contact@pitago.co.il"
        }
      ]
    }
  ]
};

const App = () => {
  return <TextPage {...aboutContent} />;
};

export default App;
