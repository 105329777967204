import React from 'react';
import { CssBaseline, Container, Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../theme';
import HomeHeader from './HomeHeader';
import HomeMainSection from './HomeMainSection';
import HomeFeatures from './HomeFeatures';
import HomeFooter from './HomeFooter';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
        <Container maxWidth={false} disableGutters>
          <Box sx={{ maxWidth: 1000, mx: 'auto', bgcolor: 'background.paper', p: 3 }}>
            <HomeHeader />
            <HomeMainSection />
            <HomeFeatures />
            <HomeFooter />
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
