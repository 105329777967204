import React, { useState } from 'react';
import { Box, Button, Typography, Container, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { sendEmailVerification } from 'firebase/auth';
import { auth } from '../../firebase';

const EmailAuthContainer = styled(Container)(({ theme }) => ({
  direction: 'rtl',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  backgroundColor: theme.palette.background.paper,
}));

const MessageContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  backgroundColor: theme.palette.background.default,
  maxWidth: '400px',
  marginBottom: theme.spacing(2),
}));

const ResendButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const EmailAuth = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleResendEmail = async () => {
    setLoading(true);
    try {
      await sendEmailVerification(auth.currentUser);
      setMessage('האימייל נשלח שוב בהצלחה.');
    } catch (error) {
      setMessage('אירעה שגיאה בשליחת האימייל. נסו שוב מאוחר יותר.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <EmailAuthContainer>
      <MessageContainer>
        <Typography variant="h5" gutterBottom>
          אישור אימייל
        </Typography>
        <Typography variant="body1">
          שלחנו אליך אימייל לאישור. נא לאשר את האימייל כדי להמשיך.
        </Typography>
        {message && <Typography variant="body2" color="textSecondary">{message}</Typography>}
        {loading ? (
          <CircularProgress sx={{ marginTop: 2 }} />
        ) : (
          <ResendButton variant="contained" color="primary" onClick={handleResendEmail}>
            שלח שוב
          </ResendButton>
        )}
      </MessageContainer>
    </EmailAuthContainer>
  );
};

export default EmailAuth;
