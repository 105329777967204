import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Button, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateOnboardingState } from '../User/userSlice';
import Faq from '../Faq/Faq';

export default function PlanSelection() {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const handleNavigation = async () => {
            if (selectedPlan) {
                if (selectedPlan === 'free') {
                    try {
                        dispatch(updateOnboardingState('completed')).unwrap()
                    } catch (error) {
                        console.error('Failed to set onboarding state:', error);
                    }
                } else {
                    navigate('/payment');
                }
            }
        };

        handleNavigation();
    }, [selectedPlan, dispatch, navigate]);

    const plans = [
        {
            id: 'free',
            title: 'תוכנית חינם',
            features: [
                'גישה לשיעורים בסיסיים',
                'ממשק פשוט',
                'תמיכה באמצעות פורום'
            ],
            color: 'primary'
        },
        {
            id: 'paid',
            title: 'תוכנית משולמת (100 שקלים/שנה)',
            features: [
                'גישה לכל השיעורים',
                'גישה לאנליזות מתקדמות',
                'תמיכה ישירה מהמורים'
            ],
            color: 'secondary'
        }
    ];

    const planStyle = (plan) => ({
        padding: 3,
        margin: 2,
        borderColor: selectedPlan === plan.id ? 'primary.main' : 'grey.300',
        borderWidth: 2,
        borderStyle: 'solid',
        backgroundColor: selectedPlan === plan.id ? 'primary.light' : '',
        cursor: 'pointer',
        '&:hover': {
            borderColor: 'primary.main',
            backgroundColor: 'primary.lighter',
        },
    });

    return (
        <Container dir="rtl">
            <Box sx={{ mt: 2, display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Typography variant="h4" component="h1" gutterBottom>
                    בחירת תוכנית
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
                {plans.map(plan => (
                    <Paper key={plan.id} sx={planStyle(plan)} onClick={() => setSelectedPlan(plan.id)}>
                        <Typography variant="h6" component="h3">{plan.title}</Typography>
                        <ul>
                            {plan.features.map(feature => <li key={feature}>{feature}</li>)}
                        </ul>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Button variant="contained" color={plan.color} onClick={() => setSelectedPlan(plan.id)}>
                                בחר תוכנית זו
                            </Button>
                        </Box>
                    </Paper>
                ))}
            </Box>
            <Faq />
        </Container>
    );
}
