import React from 'react';
import { TextField } from '@mui/material';

const TextFieldRTL = ({ label, type, value, onChange, error, helperText, ...props }) => {
  return (
    <TextField
      fullWidth
      label={label}
      type={type}
      variant="outlined"
      margin="normal"
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      InputLabelProps={{
        sx: { transformOrigin: 'top right', right: 28 },
        shrink: true,
      }}
      InputProps={{
        sx: { textAlign: 'right', '& .MuiOutlinedInput-notchedOutline': { textAlign: 'right' } },
      }}
      {...props}
    />
  );
};

export default TextFieldRTL;
