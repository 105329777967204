import { createSlice } from '@reduxjs/toolkit';

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: {
    texts: "",
    grade: null,
    subItems: [],
    topicGrades: {} // 0-1 grade for each topic
  },
  reducers: {
    initFeedback: (state, action) => {
      console.log(action.payload);
      // Correct way to update state properties
      state.texts = action.payload?.texts;
      state.grade = action.payload?.grade;
      state.subItems = action?.payload?.subItems || {};
      state.topicGrades = action?.payload?.topicGrades || {};
    },
  },
});

export const { initFeedback } = feedbackSlice.actions;

export default feedbackSlice.reducer;
