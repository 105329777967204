import React, { useState, useRef } from 'react';
import { addStyles, EditableMathField } from 'react-mathquill';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

// Add MathQuill styles
addStyles();

const CalculatorModal = ({ open, onClose, onInsert }) => {
  const [expression, setExpression] = useState('');
  const mathFieldRef = useRef(null);

  const handleButtonClick = (value) => {
    if (mathFieldRef.current) {
      mathFieldRef.current.cmd(value);
      mathFieldRef.current.focus();
      setExpression(mathFieldRef.current.latex());
    }
  };

  const handleSpecialButtonClick = (event, value) => {
    event.stopPropagation();
    if (mathFieldRef.current) {
      mathFieldRef.current.cmd(value);
      mathFieldRef.current.focus();
      setExpression(mathFieldRef.current.latex());
    }
  };

  const handleClear = () => {
    if (mathFieldRef.current) {
      mathFieldRef.current.latex('');
      setExpression('');
    }
  };

  const handleInsert = () => {
    onInsert(expression);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
        <EditableMathField
          latex={expression}
          onChange={(mathField) => setExpression(mathField.latex())}
          mathquillDidMount={(mathField) => {
            mathFieldRef.current = mathField;
          }}
          style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ccc', borderRadius: '4px', direction: 'ltr' }}
        />
        <Grid container spacing={1}>
          {['7', '8', '9', '\\div', '4', '5', '6', '\\cdot', '1', '2', '3', '-', '0', '.', '=', '+'].map((btn) => (
            <Grid item xs={3} key={btn}>
              <Button variant="outlined" fullWidth onClick={() => handleButtonClick(btn)}>
                {btn === '\\cdot' ? '·' : btn === '\\div' ? '÷' : btn}
              </Button>
            </Grid>
          ))}
          <Grid item xs={4}>
            <Button variant="outlined" fullWidth onClick={(event) => handleSpecialButtonClick(event, '\\frac')}>
              קו שבר
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button variant="outlined" fullWidth onClick={(event) => handleSpecialButtonClick(event, '^')}>
              x^y
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button variant="outlined" fullWidth onClick={(event) => handleSpecialButtonClick(event, '\\sqrt')}>
              √
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" fullWidth onClick={() => handleButtonClick('(')}>
              (
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" fullWidth onClick={() => handleButtonClick(')')}>
              )
            </Button>
          </Grid>
        </Grid>
        <Button variant="contained" color="primary" onClick={handleInsert} sx={{ marginTop: '20px', marginRight: '10px' }}>
          Insert
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleClear} sx={{ marginTop: '20px' }}>
          Clear
        </Button>
      </Box>
    </Modal>
  );
};

export default CalculatorModal;
