import React, { useState } from 'react';
import { Container, Typography, Button, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createStudent } from '../Student/studentSlice';
import TextFieldRTL from '../../components/TextFieldRTL';
import FormControlRTL from '../../components/FormControlRTL'; // Import the new component

export default function AddStudent({ selectedPlan }) {
    const [studentName, setStudentName] = useState('');
    const [classLevel, setClassLevel] = useState('');
    const [studentNameError, setStudentNameError] = useState(false);
    const [formError, setFormError] = useState('');
    const user = useSelector(state => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validateStudentName = (name) => {
        const regex = /^[A-Za-zא-ת0-9-_]{2,}$/;
        return regex.test(name);
    };

    const handleNameChange = (event) => {
        const name = event.target.value;
        setStudentName(name);
        if (!validateStudentName(name)) {
            setStudentNameError(true);
        } else {
            setStudentNameError(false);
        }
    };

    const handleClassLevelChange = (event) => {
        setClassLevel(event.target.value);
    };

    const handleSubmit = async () => {
        if (!studentName || studentNameError || !classLevel) {
            setFormError('אנא תקן את השגיאות לפני ההמשך.');
            return;
        }

        const newStudent = { name: studentName, classLevel, user: user.id };

        try {
            await dispatch(createStudent(newStudent)).
            unwrap()
            .then((result) => {
                navigate('/select_plan');
            })
            .catch((error) => {
            console.error('Failed to fetch user data:', error);
            });
    
            
            // Navigation based on the selected plan
        } catch (error) {
            console.error('Failed to create student:', error);
            setFormError('יצירת תלמיד נכשלה. אנא נסה שוב.');
        }
    };

    return (
        <Container>
            <Typography variant="h4" sx={{ mt: 2 }}>הוספת תלמיד חדש</Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
                נגדיר את התלמיד הראשון שלכם. תוכלו להוסיף תלמידים נוספים לאחר ההרשמה.
            </Typography>
            <TextFieldRTL
                label="שם או כינוי"
                variant="outlined"
                value={studentName}
                error={studentNameError}
                helperText={studentNameError ? "שם התלמיד חייב להכיל לפחות שתי אותיות ולא יכול להכיל תווים מיוחדים" : ""}
                onChange={handleNameChange}
            />
            <FormControlRTL
                label="&nbsp;&nbsp;&nbsp;כיתה"
                value={classLevel}
                onChange={handleClassLevelChange}
                error={!!formError}
                helperText={formError}
            >
                {['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט', 'י', 'יא', 'יב'].map(level => (
                    <MenuItem key={level} value={level}>כיתה {level}</MenuItem>
                ))}
            </FormControlRTL>
            <Button variant="contained" color="primary" sx={{ mt: 4 }} onClick={handleSubmit}>
                המשך
            </Button>
            {!!formError && (
                <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                    {formError}
                </Typography>
            )}
        </Container>
    );
}
