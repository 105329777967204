// src/components/TrackEvent.js

import React from 'react';
import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import logMessage from '../utils/logUtils'; 
import trackEvent from '../utils/analyticsUtils';

const TestTracking = () => {

    const handleTrackEvent = async () => {
    const analyticsEntry = {
      event: 'page_view',
      page: 'home',
      timestamp: new Date().toISOString(),
      userId: 'user_123'
    };

    // Determine the environment
    const environment = process.env.REACT_APP_ENV;

    try {
      const analyticsAPI = httpsCallable(functions, 'analyticsAPI');
      const result = await analyticsAPI({ analyticsEntry, environment });
      console.log(result.data.message);
    } catch (error) {
      console.error('Error tracking event:', error);
    }
  };

  const handleLogMessage = async () => {
    let message = "Terrible error ;)"
    let logLevel = "error"
    
    // Determine the environment

    try {
        console.log("Sending error")
        await logMessage(message, logLevel);
    } catch (error) {
      console.error('Error Logging Error:', error);
    }
  };

  return (
    <div>
    <button onClick={handleTrackEvent}>
      Track Event
    </button>
    <button onClick={handleLogMessage}>
    Logging error
  </button>          
  </div>
);
};

export default TestTracking;
