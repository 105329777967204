import React from 'react';
import { functions, httpsCallable } from '../firebase';
import { store } from '../store';

const trackEvent = ({ eventName, eventProperties }) => {
    const state = store.getState()

    const handleTrackEvent = async () => {
    const analyticsEntry = {
      event: eventName,
      ...eventProperties,
      timestamp: new Date().toISOString(),
      // Assuming user ID will be dynamically set
      userId: state.user.id
    };

    // Determine the environment
    const environment = process.env.REACT_APP_ENV;

    try {
      const analyticsAPI = httpsCallable(functions, 'analyticsAPI');
      const result = await analyticsAPI({ analyticsEntry, environment });
      console.log(result.data.message);
    } catch (error) {
      console.error('Error tracking event:', error);
    }
  };

  return (
    <button onClick={handleTrackEvent}>
      Track Event
    </button>
  );
};

export default trackEvent;
