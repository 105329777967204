import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { httpsCallable } from 'firebase/functions'; 
import { functions } from '../../firebase'; 

export const fetchUserData = createAsyncThunk(
  'user/fetchUserData',
    async ({ email, method, allowCreate, allowFetch }, { rejectWithValue }) => {
    try {
      console.log('fetchUserData', email, method, allowCreate, allowFetch);
      const getUserData = httpsCallable(functions, 'getUserData');
      const result = await getUserData({ email, method, allowCreate, allowFetch});
      if (result.data.found) {
        return { userId: result.data.userId, userData: result.data.userData };
      } else {
        return rejectWithValue(result.data.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateOnboardingState = createAsyncThunk(
  'user/updateOnboardingState',
  async (newState, { getState, rejectWithValue }) => {
    const state = getState().user;
    try {
      const updateUserOnboardingState = httpsCallable(functions, 'updateUserOnboardingState');
      const result = await updateUserOnboardingState({
        userId: state.id,
        newState: newState,
      });
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: '',
    data: null,
    email: null,
    name: null,
    displayName: null,
    record: {},
    payment: null,
    plan: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload?.email;
        state.displayName = action.payload?.displayName;
    },
    clearUser: (state) => {
      state.user = null;
      state.id = '';
      state.data = null;
    },
    setOnboardingState: (state, action) => {
      const newState = action.payload;
      const now = new Date().toISOString();
      if (state.data && state.data.onboarding) {
        state.data.onboarding.state = newState;
        state.data.onboarding.states[newState] = now;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        console.log('fetchUserData.fulfilled', action.payload.userId, action.payload.userData	)
        state.status = 'succeeded';
        state.id = action.payload.userId;
        state.data = action.payload.userData;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateOnboardingState.fulfilled, (state, action) => {
        const { newState } = action.payload;
        const now = new Date().toISOString();
        if (!state.data.onboarding) state.data.onboarding = { state: null, states: {} };
        state.data.onboarding.state = newState;
        state.data.onboarding.states[newState] = now;
      });  
    },
});

export const { setUser, clearUser, setOnboardingState } = userSlice.actions;

export default userSlice.reducer;
