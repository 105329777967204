import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  CssBaseline,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import FeedbackIcon from '@mui/icons-material/Feedback'; 
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import GavelIcon from '@mui/icons-material/Gavel'; // Icon for Terms
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'; // Icon for Privacy Policy
import AccessibilityIcon from '@mui/icons-material/Accessibility'; // Icon for Accessibility
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';
import { clearUser } from '../User/userSlice';
import { auth } from '../../firebase';

function Layout({ children }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleOptionClick = (action) => {
    action();
    setDrawerOpen(false);
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const drawerOptions = [
    {
      title: "בית",
      icon: <HomeIcon />,
      action: () => navigate('/')
    },
    {
      title: "משוב",
      icon: <FeedbackIcon />,
      action: () => navigate('/feedback')
    },
    { divider: true },
    {
      title: "החשבון שלי",
      icon: <AccountBoxIcon />,
      action: () => navigate('/account')
    },
    {
      title: "היסטוריה",
      icon: <HistoryIcon />,
      action: () => navigate('/history')
    },
    {
      title: "העדפות",
      icon: <SettingsIcon />,
      action: () => navigate('/settings')
    },
    { divider: true },
    /*{
      title: "Show More",
      icon: showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />,
      action: toggleShowMore
    },*/
    { showMore: true, content: [
      {
        title: "אודות",
        icon: <InfoIcon />,
        action: () => navigate('/about')
      },
      {
        title: "תנאי שימוש",
        icon: <GavelIcon />,
        action: () => navigate('/terms')
      },
      {
        title: "מדיניות פרטיות",
        icon: <PrivacyTipIcon />,
        action: () => navigate('/privacy')
      },
      {
        title: "מדיניות נגישות",
        icon: <AccessibilityIcon />,
        action: () => navigate('/accessibility')
      },
      {
        title: "יצירת קשר",
        icon: <ContactMailIcon />,
        action: () => navigate('/contact')
      }
    ]},
    { divider: true },
    {
      title: "התנתקות",
      icon: <ExitToAppIcon />,
      action: async () => {
        try {
          await auth.signOut();
          dispatch(clearUser());
          console.log('User logged out');
          navigate('/login');
        } catch (error) {
          console.error('Error logging out:', error);
        }
      }
    }
  ];

  const renderDrawerOptions = (options) => {
    return options.map((item, index) => {
      if (item.divider) {
        return <Divider key={index} />;
      }
      if (item.showMore) {
        return (
          <React.Fragment key={index}>
            <ListItemButton onClick={item.action}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItemButton>
            <Collapse in={showMore} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.content.map((subItem, subIndex) => (
                  <ListItemButton key={subIndex} onClick={() => handleOptionClick(subItem.action)} sx={{ pl: 2 }}>
                    <ListItemIcon>{subItem.icon}</ListItemIcon>
                    <ListItemText primary={subItem.title} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        );
      }
      return (
        <ListItemButton key={index} onClick={() => handleOptionClick(item.action)}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.title} />
        </ListItemButton>
      );
    });
  };

  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
      <CssBaseline />
      <Container maxWidth="lg" disableGutters>
        <Box sx={{ maxWidth: 1000, mx: 'auto', position: 'relative' }}>
          <AppBar position="static" sx={{ width: '100%', left: 'auto', right: 'auto' }}>
            <Container maxWidth="md" disableGutters>
              <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" sx={{ flexGrow: 1, mr: 2 }}>
                  שלום [User Name]!
                </Typography>
              </Toolbar>
            </Container>
          </AppBar>
        </Box>
      </Container>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <List sx={{ width: 250 }}>
          {renderDrawerOptions(drawerOptions)}
        </List>
      </Drawer>
      <Container maxWidth={false} disableGutters>
        <Box sx={{ maxWidth: 1000, mx: 'auto', bgcolor: 'background.paper', p: 3, padding: 0 }}>
          <Box component="main" sx={{ flexGrow: 1, padding: 3}}>
            {children}
          </Box>
          <Footer />
        </Box>
      </Container>
    </Box>
  );
}

export default Layout;
