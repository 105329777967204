import React from 'react';
import TextPage from '../TextPage/TextPage';

const privacyPolicyContent = {
    title: "מדיניות פרטיות",
    introduction: "ב-פיתגו אנו מחויבים לשמור על פרטיות המידע האישי שלך. מדיניות פרטיות זו מתארת כיצד אנו אוספים, משתמשים ומגנים על המידע האישי שלך כאשר אתה משתמש באתרנו ובשירותים שלנו בכתובת פיתגו.co.il.",
    topics: [
      {
        title: "1. מידע שנאסף",
        subtopics: [
          {
            title: "1.1",
            text: "אנו אוספים מידע אישי שמסופק על ידך בעת הרשמה לשירותים שלנו, כגון שם, כתובת דוא\"ל, ומספר טלפון."
          },
          {
            title: "1.2",
            text: "אנו עשויים לאסוף מידע אוטומטי באמצעות קובצי Cookie וטכנולוגיות דומות, כגון כתובת IP, סוג דפדפן, ונתוני שימוש באתר."
          }
        ]
      },
      {
        title: "2. שימוש במידע",
        subtopics: [
          {
            title: "2.1",
            text: "אנו משתמשים במידע האישי שלך על מנת לספק ולשפר את השירותים שלנו, להתאים אישית את חווית המשתמש שלך, ולתקשר עמך לגבי השירותים שלנו."
          },
          {
            title: "2.2",
            text: "המידע האוטומטי שנאסף משמש לניהול ולשיפור האתר והשירותים, כולל ניתוח נתוני שימוש ומגמות."
          },
          {
            title: "2.3",
            text: "אנו משתמשים ב-API של צד ג' כדי לספק חלק מהשירותים שלנו. תוכן המשתמש עשוי להיחשף לספקי צד ג' אלה לצורך עיבוד הנתונים ומתן השירותים המבוקשים."
          }
        ]
      },
      {
        title: "3. שיתוף מידע",
        subtopics: [
          {
            title: "3.1",
            text: "אנו לא נשתף את המידע האישי שלך עם צדדים שלישיים ללא הסכמתך, למעט במקרים הנדרשים לצורך אספקת השירותים, עמידה בדרישות חוקיות, או הגנה על זכויותינו."
          },
          {
            title: "3.2",
            text: "אנו עשויים לשתף מידע מצטבר ואנונימי עם צדדים שלישיים לצרכי מחקר ושיפור השירותים שלנו."
          },
          {
            title: "3.3",
            text: "המידע האישי שלך נשמר ומעובד בעננים של ספקי צד ג' שאיתם אנו עובדים כדי להבטיח את זמינות השירותים והבטחתם."
          }
        ]
      },
      {
        title: "4. הגנה על מידע",
        subtopics: [
          {
            title: "4.1",
            text: "אנו נוקטים אמצעים טכניים וארגוניים כדי להגן על המידע האישי שלך מפני גישה בלתי מורשית, שימוש לא נכון, או חשיפה."
          },
          {
            title: "4.2",
            text: "למרות מאמצינו להגן על המידע שלך, אין באפשרותנו להבטיח אבטחה מוחלטת של הנתונים המועברים באינטרנט."
          }
        ]
      },
      {
        title: "5. זכויות המשתמש",
        subtopics: [
          {
            title: "5.1",
            text: "הנך רשאי לעיין, לתקן או למחוק את המידע האישי שלך בכל עת על ידי פנייה אלינו בכתובת הדוא\"ל המפורטת מטה."
          },
          {
            title: "5.2",
            text: "אם יש לך שאלות או חששות לגבי פרטיות המידע שלך, תוכל לפנות אלינו לקבלת מידע נוסף."
          }
        ]
      },
      {
        title: "6. שינויים במדיניות הפרטיות",
        subtopics: [
          {
            title: "6.1",
            text: "אנו עשויים לעדכן מדיניות פרטיות זו מעת לעת. הודעה על שינויים משמעותיים תפורסם באתרנו או תישלח אליך בדוא\"ל."
          },
          {
            title: "6.2",
            text: "המשך השימוש בשירותים לאחר השינויים מהווה הסכמה שלך למדיניות המעודכנת."
          }
        ]
      },
      {
        title: "7. יצירת קשר",
        subtopics: [
          {
            title: "",
            text: "אם יש לך שאלות או בקשות בנוגע למדיניות פרטיות זו, אנא צור קשר: דוא\"ל: privacy@פיתגו.co.il"
          }
        ]
      }
    ]
  };

const App = () => {
  return <TextPage {...privacyPolicyContent} />;
};

export default App;
