import React, { useState } from 'react';
import { Container, TextField, Button, Typography, MenuItem, FormControl, InputLabel, Select, Rating, Snackbar, Alert, Fade } from '@mui/material';
import { functions, httpsCallable } from '../../firebase'; // Ensure you have configured Firestore in firebase.js
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ThemeProvider as Emotion10ThemeProvider } from '@emotion/react';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';

const theme = createTheme({
  direction: 'rtl', // Both here and <body dir="rtl">
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const FeedbackForm = () => {
  const [feedbackType, setFeedbackType] = useState('');
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [pageUrl] = useState(window.location.href); // Do not allow editing
  const [rating, setRating] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!feedback.trim()) {
      setSnackbarMessage('שדה המשוב לא יכול להיות ריק');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const saveFeedback = httpsCallable(functions, 'saveFeedback');
    try {
      const result = await saveFeedback({ feedbackType, feedback, email, userName, pageUrl, rating });
      if (result.data.success) {
        setSnackbarMessage('המשוב נשלח בהצלחה');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setFeedbackType('');
        setFeedback('');
        setEmail('');
        setUserName('');
        setRating(0);
      }
    } catch (error) {
      console.error('שגיאה בשליחת המשוב:', error);
      setSnackbarMessage('שגיאה בשליחת המשוב');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <CacheProvider value={cacheRtl}>
      <Emotion10ThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <Container maxWidth="sm" dir="rtl">
            <Typography variant="h4" component="h1" gutterBottom>
              שלח משוב
            </Typography>
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth margin="normal">
                <InputLabel>סוג המשוב</InputLabel>
                <Select
                  value={feedbackType}
                  onChange={(e) => setFeedbackType(e.target.value)}
                >
                  <MenuItem value="Bug Report">דיווח על תקלה</MenuItem>
                  <MenuItem value="Feature Request">בקשת תכונה</MenuItem>
                  <MenuItem value="General Feedback">משוב כללי</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="משוב"
                variant="outlined"
                fullWidth
                margin="normal"
                multiline
                rows={4}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                required
              />
              <TextField
                label="אימייל (לא חובה)"
                variant="outlined"
                fullWidth
                margin="normal"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="שם (לא חובה)"
                variant="outlined"
                fullWidth
                margin="normal"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <TextField
                label="כתובת העמוד (לא חובה)"
                variant="outlined"
                fullWidth
                margin="normal"
                value={pageUrl}
                disabled
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>דרג</InputLabel>
                <Rating
                  name="rating"
                  value={rating}
                  onChange={(e, newValue) => setRating(newValue)}
                />
              </FormControl>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                שלח
              </Button>
            </form>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              onClose={() => setSnackbarOpen(false)}
              TransitionComponent={Fade}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert
                onClose={() => setSnackbarOpen(false)}
                severity={snackbarSeverity}
                sx={{ width: '100%', '& .MuiAlert-action': { marginLeft: 'auto', paddingLeft: '16px' } }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Container>
        </ThemeProvider>
      </Emotion10ThemeProvider>
    </CacheProvider>
  );
};

export default FeedbackForm;
