import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';

function HomeMainSection() {
  return (
    <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography component="h2" variant="h3" color="text.primary" gutterBottom>
            שפרו את היכולות שלכם במתמטיקה
          </Typography>
          <Typography variant="h5" color="text.secondary" paragraph>
            אנו מציעים שיעורים פרטיים במתמטיקה לכל הרמות והגילאים.
          </Typography>
          <Button variant="contained" color="primary">
            התחילו עכשיו
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src="/assets/home-cover-v1.webp"
            alt="מורה פרטי למתמטיקה"
            style={{ width: '100%', height: 'auto', width: 400, height: 400 }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomeMainSection;
