import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import FeedbackFormDialog from '../FeedbackFormDialog/FeedbackFormDialog';

const Footer = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Container maxWidth="md" style={{ marginTop: '2rem', padding: '1rem', borderTop: '1px solid #ddd', textAlign: 'center' }} dir="rtl">
        <Typography variant="body1">
          <Button color="primary" onClick={handleClickOpen}>
            שלח משוב
          </Button>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          &copy; {new Date().getFullYear()} My Application
        </Typography>
      </Container>
      <FeedbackFormDialog open={open} onClose={handleClose} />
    </>
  );
};

export default Footer;
