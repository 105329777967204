import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControl,
  FormControlLabel
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector, useDispatch } from 'react-redux';
import { addStyles } from 'react-mathquill';
import { chunkEquationAndText } from './chunkEquationAndText';
import ForwardedEditableMathField from './ForwardedEditableMathField';
import LineGraph from '../charts/LineGraph';
import confetti from 'canvas-confetti';
import { setLessonState } from '../Lesson/lessonSlice';
import { getChartData } from '../../utils/chartUtils';
import MyMathInput from '../TestMathInput';
import { InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';

// Add MathQuill styles
addStyles();

const Question = ({ data, onAnswer, feedback, lessonState, responses, setResponses, initValue, qKey }) => {
  const [showSubmit, setShowSubmit] = useState(false);
  const [segments, setSegments] = useState([{ type: 'latex', content: '' }]);
  const editableFieldRefs = useRef(segments.map(() => React.createRef()));
  const dispatch = useDispatch();
  const clearQuestion = useSelector(state => state.lesson.clearQuestion);
  const [multiChoiceSelected, setMultiChoiceSelected] = useState([]);
  const [expandedSubItems, setExpandedSubItems] = useState({ 0: true });
  const mathInputRef = useRef();
  const subItemRefs = useRef([]);
  const maxSelections = 1;

  const isHebrew = (char) => /[\u0590-\u05FF]/.test(char);

  useEffect(() => {
    if (editableFieldRefs.current.length !== segments.length) {
      editableFieldRefs.current = segments.map(() => React.createRef());
    }
  }, [segments]);

  useEffect(() => {
    let shouldShowSubmit = data.elements.some(element => element.type === 'input' || element.type === 'multichoice');
    setShowSubmit(shouldShowSubmit);
  }, [data]);

  const drawShape = (shape) => {
    return <div>*SHAPE*</div>;
  };

  const drawChart = (content) => {
    let chartData = getChartData(content);
    return <LineGraph graphData={chartData} />;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const combinedResponse = mathInputRef.current ? mathInputRef.current.getFieldValue() : null;
    const subItemResponses = subItemRefs.current.map(ref => ref.current ? ref.current.getFieldValue() : null);

    onAnswer(data.id, combinedResponse, subItemResponses);
    dispatch(setLessonState("get_feedback"));

  };

  const handleMultiChoiceChange = (option,optionIndex,isMain) => {
    setMultiChoiceSelected(prevSelected => {
      if (maxSelections === 1) {
        const newSelection = [option];
        // Is not isMain -> all subItems are submitted with the submit button
        if (isMain) { 
          onAnswer(data.id, {selectedOption:optionIndex+1}, null);
          console.log("Submitting")
        }
        console.log(isMain)
        return newSelection;
      }
      if (prevSelected.includes(option)) {
        return prevSelected.filter(selectedOption => selectedOption !== option);
      } else {
        return [...prevSelected, option];
      }
    });
  };

  const handleAccordionChange = (index) => (event, isExpanded) => {
    setExpandedSubItems(prevExpanded => ({
      ...prevExpanded,
      [index]: isExpanded,
    }));
  };

  const getFirstLineOfFirstItem = (elements) => {
    for (const element of elements) {
      if (element.type === 'text' && element.content && element.content.text) {
        return element.content.text.split('\n')[0]; // Return the first line of the text
      }
    }
    return 'Sub Item';
  };

  const displaySubItems = (subItems) => {
    subItemRefs.current = subItems.map(() => React.createRef());

    return subItems.map((subElement, subElementIndex) => (
      <Accordion key={subElementIndex} expanded={expandedSubItems[subElementIndex]} onChange={handleAccordionChange(subElementIndex)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>שאלה מס' {subElementIndex + 1}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {displayQuestion(subElement, subItemRefs.current[subElementIndex], feedback.subItems[subElementIndex], qKey+"_"+subElementIndex,false)}
        </AccordionDetails>
      </Accordion>
    ));
  };

  const displayQuestion = (q, inputRef, feedback, qKey, isMain) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 2 }}>
        {
        q.elements.map((element, elementIndex) => {
          let questionType = element.type;
          let questionContent = element.content;
          switch (questionType) {
            case 'text':
            case 'description':
              return (
                <Box key={elementIndex} sx={{ width: '100%', textAlign: 'right', mb: 2 }}>
                  {chunkEquationAndText(questionContent.text, elementIndex)}
                </Box>
              );
            case 'chart':
              return (
                <Box key={elementIndex} sx={{ width: '100%', textAlign: 'right', mb: 2 }}>
                  {drawChart(questionContent)}
                </Box>
              );
            case 'shape':
              return (
                <Box key={elementIndex} sx={{ width: '100%', textAlign: 'right', mb: 2 }}>
                  {drawShape(questionContent)}
                </Box>
              );
            case 'input':
              return (
                <Box key={elementIndex} sx={{ mt: 1, width: '100%', border: 0, textAlign: 'right', mb: 2 }}>
                  <MyMathInput ref={inputRef} key={qKey} feedback={feedback?.texts}></MyMathInput>
                </Box>
              );
            case 'multichoice':
              return (
                <Box key={elementIndex} sx={{ width: '100%', textAlign: 'right', mb: 2 }}>
                  {questionContent.options.map((option, optionIndex) => (
                    <Box
                      key={optionIndex}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: '10px',
                        borderRadius: '5px',
                        border: multiChoiceSelected.includes(option) ? '0px solid #3f51b5' : '0px solid #ccc',
                        backgroundColor: multiChoiceSelected.includes(option) ? '#f0f0f0' : '#fff',
                        marginBottom: '5px'
                      }}
                      onClick={() => handleMultiChoiceChange(option,optionIndex,isMain)}
                    >
                      <Checkbox
                        checked={multiChoiceSelected.includes(option)}
                        onChange={() => handleMultiChoiceChange(option,optionIndex,isMain)}
                        color="primary"
                      />
                      <Typography variant="body1" sx={{ marginLeft: '10px', fontSize: '1.2em' }}>
                        {chunkEquationAndText(option, optionIndex)}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              );
            case 'subItems':
              return (
                <Box key={elementIndex} sx={{ width: '100%', textAlign: 'right', mb: 2 }}>
                  {displaySubItems(questionContent.items)}
                </Box>
              );
            default:
              return null;
          }
        })}
        {lessonState === "get_answer" && showSubmit && (
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            שלח
          </Button> 
        )}
        {lessonState === "get_flow" && feedback && feedback.texts && feedback.texts.main.text && (
          <Box sx={{ mt: 2, width: '100%', textAlign: 'right' }}>
            <Typography variant="h6">משוב</Typography>
            {feedback.texts.main.text.split("\n").map((explanationLine, index) => (
              <Box key={index} sx={{ mt: 1 }}>{chunkEquationAndText(explanationLine)}</Box>
            ))}
            {feedback?.grade > 0 && (<Typography sx={{ mt: 1 }}>ציון: {feedback.grade}</Typography>)}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ width: '100%', textAlign: 'right', mb: 3 }}>
      {displayQuestion(data, mathInputRef, feedback, qKey, true)}
    </Box>
  );
};

export default Question;
