import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const LineGraph = ({ graphData }) => {
    console.log('graphData:', graphData);  // Console log to check the data
    const { labels, datasets, options } = graphData;

  const data = {
    labels: labels,
    datasets: datasets,
  };

  return <Line data={data} options={options} />;
};

LineGraph.propTypes = {
  graphData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(PropTypes.number).isRequired,
        fill: PropTypes.bool,
        borderColor: PropTypes.string,
        tension: PropTypes.number,
      })
    ).isRequired,
    options: PropTypes.object,
  }).isRequired,
};

export default LineGraph;
