import React from 'react';
import { Card, CardContent, Typography, Button, Container } from '@mui/material';
import { motion } from 'framer-motion';

const TopicTransitionCard = ({ title, content, buttonText, onClick }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      style={{ marginBottom: '20px' }}
    >
      <Card>
        <CardContent>
          <Typography variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {content}
          </Typography>
          <Button variant="contained" color="primary" onClick={onClick} style={{ marginTop: '10px' }}>
            {buttonText}
          </Button>
        </CardContent>
      </Card>
    </motion.div>
  );
};

export default TopicTransitionCard;