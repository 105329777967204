import React from 'react';
import { Typography, Box, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function PlanSelection() {

    const faqs = [
        {
            question: 'מה ההבדל בין התוכניות?',
            answer: 'התוכנית החינמית מציעה גישה בסיסית, בעוד שהתוכנית המשולמת מציעה גישה מלאה ותמיכה משופרת.'
        },
        {
            question: 'האם ניתן לבטל את המנוי בכל עת?',
            answer: 'כן, ניתן לבטל את המנוי בכל עת מהגדרות החשבון.'
        }
    ];

    <Box sx={{mt: 5}}>
    <Typography variant="h5" component="h2" gutterBottom>
        שאלות נפוצות
    </Typography>
    {faqs.map((faq, index) => (
        <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>{faq.answer}</Typography>
            </AccordionDetails>
        </Accordion>
    ))}              
    </Box>
}