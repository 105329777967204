import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import pageReducer from './features/Page/pageSlice';
import lessonReducer from './features/Lesson/lessonSlice';
import feedbackReducer from './features/Feedback/feedbackSlice';
import userReducer from './features/User/userSlice';
import studentReducer from './features/Student/studentSlice';

// Configuration for redux-persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user','students','lesson'], // Specify which slices you want to persist
};

// Combine all reducers
const rootReducer = combineReducers({
  page: pageReducer,
  lesson: lessonReducer,
  feedback: feedbackReducer,
  user: userReducer,
  students: studentReducer,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with the persisted reducer
const store = configureStore({
  reducer: persistedReducer,
});

// Create a persistor
const persistor = persistStore(store);

export { store, persistor };
