import React, { useState } from 'react';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase';
import { useSelector } from 'react-redux';
import logMessage from '../../utils/logUtils'; 

const ForgotPasswordContainer = styled(Container)(({ theme }) => ({
  direction: 'rtl',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  backgroundColor: theme.palette.background.paper,
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  backgroundColor: theme.palette.background.default,
  width: '100%',
  maxWidth: '400px',
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const ForgotPassword = () => {
  const [submitted, setSubmitted] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const email = useSelector((state) => state.user.email);

  const handleEmailChange = (e) => {
    if (submitted) {
      setSubmitted(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setEmailSent(true);
    } catch (error) {
      logMessage(error.message,'error')
    }
  };

  return (
    <ForgotPasswordContainer>
      <FormContainer>
        <Typography variant="h5" gutterBottom>
          שכחת סיסמה
        </Typography>
          <SubmitButton onClick={handleSubmit} type="submit" variant="contained" color="primary">
            שלח אימייל לאיפוס סיסמה
          </SubmitButton>
        {emailSent && (
          <Typography variant="body1" color="primary" mt={2}>
            אימייל לאיפוס סיסמה נשלח.
          </Typography>
        )}
      </FormContainer>
    </ForgotPasswordContainer>
  );
};

export default ForgotPassword;
