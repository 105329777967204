import React, { useState } from 'react';
import { Container, Typography, Box, TextField, Button, Alert } from '@mui/material';
import { functions } from '../../firebase'; // Ensure you have configured Firestore in firebase.js
import { useDispatch, useSelector } from 'react-redux';
import Faq from '../Faq/Faq'; // ייבוא קומפוננט ה-FAQ
import { httpsCallable } from 'firebase/functions'; // ייבוא הפונקציה הנכונה מפיירבייס

const ContactPage = () => {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const userEmail = useSelector((state) => state.user.email); // קבלת האימייל מה-Redux
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (message.trim() === '') {
      setFeedbackSubmitted('empty');
      return;
    }

    const saveContact = httpsCallable(functions, 'saveContact'); // שימוש בפונקציה הנכונה
    try {
      const result = await saveContact({ name, email: userEmail, message });
      if (result.data.success) {
        setFeedbackSubmitted(true);
        setName('');
        setMessage('');
      }
    } catch (error) {
      console.error('Error submitting contact message:', error);
      setFeedbackSubmitted('error');
    }
  };

  return (
    <Container>
      <Typography variant="h3" gutterBottom align="right">
        יצירת קשר
      </Typography>
      <Box mt={4}>
        <Typography variant="body1" gutterBottom align="right">
          אם יש לכם שאלות, הערות או בעיות, נשמח לעזור! אנא מלאו את הטופס הבא כדי לשלוח את פנייתכם.
        </Typography>
        <TextField
          label="שמך"
          fullWidth
          margin="normal"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          label="הפנייה שלך"
          fullWidth
          margin="normal"
          variant="outlined"
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleSubmit}>
          שלח פנייה
        </Button>
        {feedbackSubmitted === 'empty' && (
          <Alert severity="warning" sx={{ mt: 2 }}>
            אנא מלאו את שדה הפנייה לפני השליחה.
          </Alert>
        )}
        {feedbackSubmitted === true && (
          <Alert severity="success" sx={{ mt: 2 }}>
            תודה! קיבלנו את פנייתכם ונחזור אליכם בהקדם האפשרי.
          </Alert>
        )}
        {feedbackSubmitted === 'error' && (
          <Alert severity="error" sx={{ mt: 2 }}>
            אירעה שגיאה בשליחת הפנייה. אנא נסו שוב מאוחר יותר.
          </Alert>
        )}
      </Box>
      <Box mt={4}>
        <Typography variant="h5" gutterBottom align="right">
          פרטי יצירת קשר
        </Typography>
        <Typography variant="body1" gutterBottom align="right">
          <strong>אימייל לתמיכה:</strong> <a href="mailto:support@pitago.co.il">support@servicex.co.il</a>
        </Typography>
      </Box>
      <Box mt={4}>
        <Faq />
      </Box>
    </Container>
  );
};

export default ContactPage;
