import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function UserOnboarding() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/add_student')
  });



  return (
    <div>
      <h2>User Onboarding</h2>
      <div>
      <Button variant="contained" color="primary" onClick={() => navigate('/dashboard')}>
        Sign Up
      </Button>        
      </div>
    </div>
  );
}

export default UserOnboarding;
