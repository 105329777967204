import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function HomeHeader() {
  const navigate = useNavigate();

  const handleSignIn = () => {
    navigate('/signin');
  };

  const handleSignUp = () => {
    navigate('/signup');
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            ברוכים הבאים למורה למתמטיקה
          </Typography>
          <Box>
            <Button
              variant="contained"
              color="secondary"
              sx={{ bgcolor: 'white', color: 'primary.main', mx: 1, fontSize: '1.2rem'  }}
              onClick={handleSignIn}
            >
              התחברות
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ bgcolor: 'white', color: 'primary.main', mx: 1, fontSize: '1.2rem' }}
              onClick={handleSignUp}
            >
              הרשמה
            </Button>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default HomeHeader;
