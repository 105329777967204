import { functions, httpsCallable } from '../firebase';

const logMessage = async (message, level = 'info') => {
  const logToSentry = httpsCallable(functions, 'logMessage');
  try {
    const result = await logToSentry({ message, level });
    if (!result.data.success) {
      console.error('Failed to log message to Sentry:', result.data.error);
    }
  } catch (error) {
    console.error('Error calling logMessage function:', error);
  }
};

export default logMessage;
