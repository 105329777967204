import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStudents, createStudent, updateStudent, deleteStudent } from './studentSlice';
import { Container, Typography, Box, Button, List, ListItem, ListItemText, CircularProgress, Alert, ThemeProvider, createTheme } from '@mui/material';

const Students = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const students = useSelector((state) => state.students?.students);
  const status = useSelector((state) => state.students?.status);
  const error = useSelector((state) => state.students?.error);

  useEffect(() => {
    console.log(user.id);
    dispatch(fetchStudents({ userId: user.id }));
  }, [dispatch, user.id]);

  const handleAddStudent = () => {
    const newStudent = { name: 'New Student', class: 4 }; // Example student data
    dispatch(createStudent(newStudent));
  };

  const handleUpdateStudent = (id) => {
    const updatedData = { name: 'Updated Student', age: 11 }; // Example updated data
    dispatch(updateStudent({ id, studentData: updatedData }));
  };

  const handleDeleteStudent = (id) => {
    dispatch(deleteStudent(id));
  };

  // Create a theme with RTL direction
  const theme = createTheme({
    direction: 'rtl',
    typography: {
      fontFamily: 'Roboto, Arial, sans-serif',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container>
        {students && students.length > 0 ? (
          <Box>
            <Typography variant="h4" gutterBottom>
              Students
            </Typography>
            {status === 'loading' && <CircularProgress />}
            {status === 'failed' && <Alert severity="error">Error: {error}</Alert>}
            <List>  
              {students.map((student) => (
                <ListItem key={student.id} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <ListItemText primary={`${student.name} - ${student.age}`} />
                  <Box>
                    <Button variant="contained" color="primary" onClick={() => handleUpdateStudent(student.id)} sx={{ mr: 1 }}>
                      Update
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => handleDeleteStudent(student.id)}>
                      Delete
                    </Button>
                  </Box>
                </ListItem>
              ))}
            </List>
            <Button variant="contained" color="primary" onClick={handleAddStudent}>
              Add Student
            </Button>
          </Box>
        ) : (
          <Typography variant="h6">טוען...</Typography>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default Students;
