import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

function Features() {
  return (
    <Box sx={{ pt: 8, pb: 6 }}>
      <Typography variant="h4" align="center" gutterBottom>
        המאפיינים שלנו
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Typography variant="h5">מורים מומחים</Typography>
          <Typography>המורים שלנו הם מומחים בתחומם ובעלי ניסיון רב בהוראה.</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h5">שיטות הוראה מתקדמות</Typography>
          <Typography>אנו משתמשים בשיטות הוראה חדשניות ומתקדמות כדי להבטיח את הצלחת התלמידים.</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h5">גמישות בזמן</Typography>
          <Typography>ניתן לתאם שיעורים בזמנים נוחים לכל תלמיד.</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Features;
