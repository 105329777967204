import React, { useState } from 'react';
import { Box, Paper, Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Mock data for rewards and statistics
const rewards = [
  { image: '/assets/temp/reward.png', description: 'השלמת שיעור 20 ימים ברצף' },
  { image: '/assets/temp/reward.png', description: 'פתרת 10 נושאים' },
  { image: '/assets/temp/reward2.png', description: 'סיימת את החומר של כיתה ד\'' },
  // Add more rewards as needed
];

const statistics = {
  completionPercentage: 75,
  lessonsCompleted: 10,
  topicsMastered: 5
};

const Rewards = () => {
  const [selectedReward, setSelectedReward] = useState(null);

  const handleRewardClick = (reward) => {
    setSelectedReward(reward);
  };

  const handleClose = () => {
    setSelectedReward(null);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h5" gutterBottom>פרסים</Typography>
        <Grid container spacing={2}>
          {rewards.map((reward, index) => (
            <Grid item key={index}>
              <img
                src={reward.image}
                alt={`Reward ${index + 1}`}
                style={{ cursor: 'pointer', width: '100px', height: '100px' }}
                onClick={() => handleRewardClick(reward)}
              />
            </Grid>
          ))}
        </Grid>
        <Dialog open={!!selectedReward} onClose={handleClose}>
          <DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {selectedReward && (
              <Box>
                <img
                  src={selectedReward.image}
                  alt="Selected Reward"
                  style={{ width: '100%', height: 'auto' }}
                />
                <Typography variant="body1" sx={{ mt: 2 }}>
                  {selectedReward.description}
                </Typography>
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </Paper>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5" gutterBottom>הישגים</Typography>
        <Typography variant="h6" sx={{ mb: 1 }}>
          סיימת <Box component="span" sx={{ background: 'lightblue', borderRadius: '4px', padding: '4px', boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)' }}>{statistics.completionPercentage}%</Box> מתוכנית הלימודים
        </Typography>
        <Typography variant="h6" sx={{ mb: 1 }}>
          השלמת <Box component="span" sx={{ background: 'lightgreen', borderRadius: '4px', padding: '4px', boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)' }}>{statistics.lessonsCompleted}</Box> שיעורים
        </Typography>
        <Typography variant="h6">
          סיימת הצלחה <Box component="span" sx={{ background: 'lightcoral', borderRadius: '4px', padding: '4px', boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)' }}>{statistics.topicsMastered}</Box> נושאים
        </Typography>
      </Paper>
    </Box>
  );
};

export default Rewards;
