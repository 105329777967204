import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import Linkify from 'react-linkify';

const TextPage = ({ title, introduction, topics }) => {
  return (
    <Container>
      <Typography variant="h3" gutterBottom align="right">
        {title}
      </Typography>
      <Typography variant="body1" gutterBottom align="right">
        {introduction}
      </Typography>
      {topics.map((topic, index) => (
        <Box key={index} mt={4}>
          <Typography variant="h5" gutterBottom align="right">
            {topic.title}
          </Typography>
          <List>
            {topic.subtopics.map((subtopic, subIndex) => (
              <ListItem 
                key={subIndex} 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'flex-start', 
                  justifyContent: 'flex-end', 
                  textAlign: 'right',
                  pl: subtopic.title ? 4 : 0 
                }}
              >
                <ListItemText
                  primary={
                    <>
                      {subtopic.title && <strong>{subtopic.title}</strong>}
                      {subtopic.title && subtopic.text ? ' ' : ''}
                      <Linkify>{subtopic.text}</Linkify>
                    </>
                  }
                  sx={{ textAlign: 'right' }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      ))}
    </Container>
  );
};

export default TextPage;
