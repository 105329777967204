import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    startBreak,
    resumeLesson,
    selectTotalRunningTimeInMinutes,
    addPendingQuestions,
    setLessonState,
    activatePendingQuestion,
    activatePendingTopic,
    popTopic,
    addTopic,
    setLastQuestionFeedback,
    setNextTopic,
    setPendingTopics,
    initLesson,
    nextLesson,
    setLessonId,
    resetSave
} from './lessonSlice';
import {
    Box,
    Typography,
    Paper,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import Question from '../Question/Question';
import { getTopic, getQuestions, getExplanation } from '../../utils/dbUtils';
import { useNavigate } from 'react-router-dom';
import { initFeedback } from '../Feedback/feedbackSlice';
import SvgIcon from '@mui/material/SvgIcon';
import { resolveStudentQuestion} from '../Student/studentSlice';
import { chunkEquationAndText } from '../Question/chunkEquationAndText';
import trackEvent from '../../utils/analyticsUtils';
import { httpsCallable } from 'firebase/functions'; 
import { functions } from '../../firebase'; 
import TopicTransitionCard from './TopicTransitionCard';

const EmbarrassedSmileyIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-10 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-4-6c0-1.1.9-2 2-2s2 .9 2 2h-4zm6 0c0-1.1.9-2 2-2s2 .9 2 2h-4zM7.5 8c.83 0 1.5.67 1.5 1.5S8.33 11 7.5 11 6 10.33 6 9.5 6.67 8 7.5 8zm9 0c.83 0 1.5.67 1.5 1.5S17.33 11 16.5 11 15 10.33 15 9.5s.67-1.5 1.5-1.5z" />
    </SvgIcon>
);

const Lesson = () => {
    const dispatch = useDispatch();
    const lesson = useSelector(state => state.lesson);
    const questions = useSelector(state => state.lesson.questions);
    const currentQuestion = questions.length > 0 ? questions[questions.length - 1] : null;
    const pendingQuestions = useSelector(state => state.lesson.pendingQuestions);
    const curStudent = useSelector(state => state.students.students[state.students.active] || null);
    const students = useSelector(state => state.students);
    const curTopic = useSelector(state => state.lesson.topicStack[state.lesson.topicStack.length - 1])
    const user = useSelector(state => state.user);
    const feedback = useSelector(state => state.feedback);
    const totalRunningTime = useSelector(selectTotalRunningTimeInMinutes);
    const [outOfQuestions, setOutOfQuestions] = useState(0); // 0 - has, 1 - no pending, 2 - no pending and used last
    const [topics, setTopics] = useState([]);
    const [expanded, setExpanded] = useState(true);
    const [fullscreen, setFullscreen] = useState(false);
    const navigate = useNavigate();
    const [dots, setDots] = useState('');
    const [explanation, setExplanation] = useState(null);
    const [flowSuggestion, setFlowSuggestion] = useState(null); // Values: review, resume, new_topic
    const [lessonEnd, setLessonEnd] = useState(false);
    const [responses, setResponses] = useState({});
    const initialRender = useRef(true);

    useEffect(() => {
        console.log(topics)
    }, [topics]);

    useEffect(() => {
        console.log("current question", currentQuestion)
        if (lesson.topicStack.length === 0) {
            navigate('/dashboard')
        }

        const fetchTopics = async () => {
            const topicDetails = await Promise.all(
                lesson.topicStack.map(async (t) => {
                    const details = await getTopic(t);
                    return details;
                })
            );
            setTopics(topicDetails);
        };

        fetchTopics();
        console.log("Lesson topics: ", lesson.topicStack)
    }, [lesson.topicStack]);

    useEffect(() => {
        const handleVisibilityChange = () => {
          if (document.hidden) {
            dispatch(startBreak());
          } else {
            dispatch(resumeLesson());
          }
        };
    
        document.addEventListener('visibilitychange', handleVisibilityChange);
    
        return () => {
          document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
      }, [dispatch]);

      useEffect(() => {
        const fetchExplanation = async () => {
            if (curTopic) {
                let curExplanation = await getExplanation(curTopic, "he");
                setExplanation(curExplanation);
            }
        };

        fetchExplanation();
    }, [curTopic]);

    useEffect(() => {
        console.log("Current student: ", curStudent)
        console.log(students)
        console.log(user)
    }, [curStudent])

    useEffect(() => {
        const saveLesson = httpsCallable(functions, 'createLesson');
    
        const saveLessonData = async () => {
            console.log("Saving lesson...");
            try {
                const response = await saveLesson({ lessonData: lesson });
                if (response?.data?.success) {
                    dispatch(setLessonId(response.data.lessonId));
                }
            } catch (error) {
                console.error("Error saving lesson:", error);
            }
        };
    
        saveLessonData();
        dispatch(resetSave());
    }, [lesson.save]);
    
    useEffect(() => {
        const updateLesson = httpsCallable(functions, 'updateLesson');
        console.log(lesson.id)
        console.log(lesson)
        if (lesson.id) {
            updateLesson({lessonId:lesson.id,lessonData:lesson})
            dispatch(resetSave());
        } else {
            resetSave({save:true})
        }
    }, [lesson.update])

    useEffect(() => {
        if (initialRender.current) {
            trackEvent("Lesson Start", {topics: lesson.originalTopics},user.id);
            initialRender.current = false;
            return;
          }
      
        if (lesson.state === "get_question" && outOfQuestions !== 2) {
            console.log("Activating question...")
            activateQuestion(user, lesson);
        }

        if (lesson.state !== "get_question" && outOfQuestions === 0 && pendingQuestions.length < 2) {
            console.log(lesson.state,currentQuestion, pendingQuestions, dispatch)
            fetchPendingQuestions();
        }
    }, [lesson.state, currentQuestion,  pendingQuestions]);

    useEffect(() => {
        if (lesson.state != "get_flow" && flowSuggestion) {
            setFlowSuggestion(null);
        }

        if (lesson.state === "get_feedback") {
            const interval = setInterval(() => {
                setDots(prevDots => {
                    if (prevDots.length === 3) {
                        return '';
                    }
                    return prevDots + '.';
                });
            }, 1000);

            return () => clearInterval(interval);
        } else {
            setDots('');
        }
    }, [lesson.state]);

    useEffect(() => {
        console.log(lesson.state)
        if (lesson.state === "get_question") {
            setResponses({});
            dispatch(initFeedback({}));            
        }
    }, [lesson.state, currentQuestion]);

    useEffect(()=> {
        console.log(questions)
    }, [questions])

    const handleStartBreak = () => {
        dispatch(startBreak());
      };
    
      const handleResumeLesson = () => {
        dispatch(resumeLesson());
      };

    
    const handleTopicTransition = (type,topic) => {
        alert(`Transitioning to new topic ${type}+${topic}`);
    }
    
    const fetchPendingQuestions = async () => {
        const MAX_PENDING_QUESTIONS = 10;
        console.log("Fetching pending questions...")
        if (lesson.topicStack.length > 0) {
            const topic = lesson.topicStack[lesson.topicStack.length - 1];
            const questions = await getQuestions(user, lesson, topic, MAX_PENDING_QUESTIONS);
            if (questions.length === 0) {
                setOutOfQuestions(currentQuestion == null || currentQuestion.status === "f" ? 2 : 1);
            } else {
                dispatch(addPendingQuestions(questions));
            }
        }
    };

    const activateQuestion = async (user, lesson) => {
        if (lesson.pendingQuestions.length > 0) {
            console.log(lesson.pendingQuestions)
            dispatch(activatePendingQuestion());
            console.log(1)
        } else {
            setOutOfQuestions(2);
        }
    };

    const getNextTopic = () => {
        // Select next topic from pendingTopics
        let nextTopic = null;
        let pendingTopics = lesson.pendingTopics;
        if (pendingTopics.length > 0) {
            // remove first item from lesson.pendingTopics
            nextTopic = pendingTopics[0];
        } else {
            // Retrieve next topic using fetch
            // Temp: use random topic
            pendingTopics = ["fraction-expansion", "freaction-simplification"];
            nextTopic = pendingTopics[0];
            dispatch(setPendingTopics(pendingTopics.slice(1)));
        }
        return nextTopic;
    }

    const updateLessonRecord = (question, feedback) => {
        const DEFAULT_FLOW_SUGGESTION = {type:"regular","topic":null, uiLevel: 0, action:()=>alert(0)}
        const LESSON_MINUTES = 1
        const LESSON_MIN_QUESTION_COUNT = 1

        console.log("Updating lesson record...");
        console.log(feedback);

        dispatch(setLastQuestionFeedback(feedback))
        let dependencies = feedback?.dependencies || {}

        let REVIEW_BLOCK_SIZE = 5
        let MAIN_BLOCK_SIZE = 10

        let safeQuestions = questions.filter(question => question.feedback);
        let reviewBlock = safeQuestions.slice(0,REVIEW_BLOCK_SIZE)
        let reviewAvg = reviewBlock.length == 0 ? 0:reviewBlock.map(q => q.feedback?.normalGrade).reduce((a, b) => a + b, 0) / reviewBlock.length   ;
        let mainBlock = safeQuestions.slice(0,MAIN_BLOCK_SIZE)
        let mainAvg = mainBlock.length == 0 ? 0:mainBlock.map(q => q.feedback?.normalGrade).reduce((a, b) => a + b, 0) / mainBlock.length;

        let isDeep = lesson.topicStack > 1;
        let isMain = lesson.topicStack === 1;

        // Check if a dependency has failed consistently

        let flowSuggestion = null;

        // Flow - top priority is lesson end. If it ends, topic suggestions are not handled
        // If lesson didn't end - topic suggestions are handled
        // If no suggestion is triggered, the regular flow of next question is handled
        if (totalRunningTime >= LESSON_MINUTES && LESSON_MIN_QUESTION_COUNT <= lesson.questions.length) {   
            flowSuggestion = {type: "lessonEnd", uiLevel: 1, action:()=>dispatch(nextLesson())}
        }

        // TODO: this is a mock calculation
        if (!flowSuggestion) {
            for (const dep in dependencies) {
                if (dependencies[dep] < 0.5) {
                    flowSuggestion = {type: "review", topic: dep, uiLevel: 1, action:()=>dispatch(addTopic(dep))}
                    break;
                }
            }
        }

        console.log("Main block avg: ", mainAvg)
        console.log("Main BLock:", mainBlock)
        console.log("Main BLock Length:", mainBlock.length)
        console.log(safeQuestions)
        if (!flowSuggestion) {
            if (isMain && mainAvg > 0.9 && mainBlock.length >= MAIN_BLOCK_SIZE) {   
                flowSuggestion = {type: "new_topic", topic: getNextTopic(), uiLevel: 1, action:()=>getNextTopic()}
            } else if (isDeep && reviewAvg > 0.9 && reviewBlock.length >= REVIEW_BLOCK_SIZE) {
                flowSuggestion = {type: "resume", topic: lesson.topicStack[lesson.topicStack.length - 2], uiLevel: 1, action:()=>dispatch(popTopic())}  
            } else {
                if (mainAvg < 0.99 && mainBlock.length >= MAIN_BLOCK_SIZE/2) {
                    flowSuggestion = {type: "explain", topic: curTopic, uiLevel: 1, action:()=>alert(5)}
                }
            }
        }	

        if (!flowSuggestion)
            flowSuggestion = DEFAULT_FLOW_SUGGESTION

        setFlowSuggestion(flowSuggestion);

    }

    const display_explanation = (explanation) => {
        explanation = "TEMP"
        return explanation.split("\n").map((line, index) => {
            return <Typography key={index} variant="body1">{chunkEquationAndText(line)}</Typography>
        })
    }

    const get_question_text = (question) => {
        const questionText = question.elements
        .map(item => item.content?.text) // Extract the text properties
        .filter(text => text !== undefined) // Optionally filter out undefined texts
        .join('\n'); // Join them with newline as separator
        return questionText
    }

    // Gets input items and send them to assessment
    // Returns:
    // [{id,grade,feedback:{},metadata:{}},...]

    const normalizeGrade = (grade,from=1,to=10) => {
        return (grade-from)/(to-from)
    }

    const assessResponse = async (inputItems) => {
        let url = process.env.REACT_APP_COMPLETION_API_URL

        const data = {
            "template": "assess",
            "data": inputItems,
            "max_threads": 5,
            "metadata": {is_json: true}
        }

        const outputItems = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                
                return data
            });

        return outputItems;
    }

    function isPlainObject(obj) {
        return Object.prototype.toString.call(obj) === '[object Object]';
      }
 
      const submit_response = async (id, mainResponse, subResponses) => {
        const resolveQuestionAnswer = httpsCallable(functions, 'resolveQuestionAnswer');
        const saveFeedback = httpsCallable(functions, 'saveFeedback');
    
        console.log("Submitting Response");
        let items = [];
        let newFeedback = {};
        if (currentQuestion.subQuestions)
            newFeedback.subItem = Array(currentQuestion.subQuestions.length);
    
        // Assess the response - send to server
        if (mainResponse !== null) {
            if (mainResponse?.hasOwnProperty('selectedOption')) {
                let correct = mainResponse.selectedOption === currentQuestion.correctResult;
                console.log(correct);
                console.log(mainResponse.selectedOption, currentQuestion.correctResult);
                newFeedback.normalGrade = correct ? 1:0;
                newFeedback.texts = { main: correct ? "יופי התשובה נכונה" : "התשובה לא נכונה" };
            } else {
                let questionText = get_question_text(currentQuestion);
                items.push({ id: "general", data: { question: questionText, answer: mainResponse } });
            }
        }
        if (subResponses)
            subResponses.forEach((sub, index) => {
                if (sub !== null) {
                    if (sub?.hasOwnProperty('selectedOption')) {
                        let correct = sub.selectedOption === currentQuestion.subQuestions[index].correctResult;
                        newFeedback.subItem[index] = {  normalGrade:correct?1:0, texts: { main: correct ? "  :) יופי התשובה נכונה" : "התשובה לא נכונה :(" } };
                    } else {
                        let questionText = get_question_text(currentQuestion);
                        items.push({ id: "general", data: { question: questionText, answer: mainResponse } });
                    }
                    let questionText = get_question_text(currentQuestion) + "\n" + get_question_text(currentQuestion.subQuestions[index]);
                    items.push({ id: `sub_${index + 1}`, data: { question: questionText, answer: sub } });
                }
            });
    
        if (items.length > 0) {
            let assessment = await assessResponse(items);
            assessment.result.map((item, index) => {
                console.log(item);
                if (item.id === "general") {
                    newFeedback.grade = item.data.grade;
                    newFeedback.normalGrade = normalizeGrade(item.data.grade)
                    newFeedback.texts = item.data.feedback;
                } else {
                    if (item.id.startsWith("sub_")) {
                        let index = parseInt(item.id.split("_")[1]) - 1;
                        newFeedback.subItem[index] = {
                            grade: item.data.grade,
                            normalGrade: normalizeGrade(item.data.grade),
                            texts: item.data.feedback
                        };
                    }
                }
            });
        }
        console.log(newFeedback);
        dispatch(initFeedback(newFeedback));
    
        console.log("Current Question:",currentQuestion)
        // Extract necessary data for ELO calculation
        const oldEloQuestion = currentQuestion.elo;
        const oldEloStudent = curStudent?.topics[curTopic]?.elo || 1500;
        const casesQuestion = currentQuestion.cases;
        const casesStudent = curStudent?.topics[curTopic]?.cases || 0;
        const isRight = (newFeedback.normalGrade-1)/9;
        const useConfidenceAdjustment = true;
        const studentId = curStudent.id;
        const questionId = currentQuestion.id;
    
        // Prepare the payload
        const payload = {
            old_elo_question: oldEloQuestion,
            old_elo_student: oldEloStudent,
            cases_question: casesQuestion,
            cases_student: casesStudent,
            is_right: isRight,
            use_confidence_adjustment: useConfidenceAdjustment,
            student_id: studentId,
            question_id: questionId,
            topic: curTopic
        };
    
        // Log the payload to check for missing parameters
        console.log("Payload for resolveQuestionAnswer:", payload);
    
        // Call the calculateNewElo function
        try {
            const eloResponse = await resolveQuestionAnswer(payload);
            console.log("Elo Response:", eloResponse);
    
            const newElo = eloResponse.data.new_elo_student;
            const newQuestionCount = eloResponse.data.newQuestionCount;
    
            // Call resolveStudentQuestion with new ELO and question count
            dispatch(resolveStudentQuestion({ studentId, topic: curTopic, newElo }));
    
        } catch (error) {
            console.error("Error calculating new ELO:", error.message);
        }
    
        // Update lesson record
        updateLessonRecord(currentQuestion, newFeedback);
    };
    
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleFullscreenClick = () => {
        setFullscreen(true);
    };

    const handleCloseFullscreen = () => {
        setFullscreen(false);
    };

    return (
        <Box dir="rtl" sx={{ padding: 0, margin: 0 }}>
            {false && (<Typography variant="h6">סטודנט</Typography>)}
            {false && curStudent && (
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="h4" gutterBottom>
                            {curStudent.name}
                        </Typography>
                        <Typography variant="h4" gutterBottom>
                            {Math.round(curStudent.topics[curTopic].elo)}
                        </Typography>
                        <Typography variant="h4" gutterBottom>
                        {curStudent.topics[curTopic].cases}
                        </Typography>
                    </Box>
                )
            }
            {lesson.topicStack.length > 1 && topics.length >= lesson.topicStack.length && (
                <Box sx={{ mb: 2, mt: 2 }}>
                    <Typography variant="h4" gutterBottom>
                        {lesson.topicStack.length>1?"חזרה: ":""}{topics[lesson.topicStack.length - 1].displayName.he}
                    </Typography>
                </Box>
            )}
            {topics.length>0 && (
                <Box sx={{ mb: 2, mt: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        {lesson.topicStack.length>1?"נושא ראשי: ":""}{topics[0].displayName.he}
                    </Typography>
                </Box>
            )}
            {explanation &&
            (<Paper sx={{ p: 2, mb: 2, position: 'relative' }}>
                <Typography variant="h6">הסבר</Typography>
                <Box
                    sx={{
                        maxHeight: expanded ? 'none' : '5em',
                        overflow: 'hidden',
                        whiteSpace: expanded ? 'normal' : 'nowrap',
                        textOverflow: 'ellipsis',
                        transition: 'max-height 0.3s ease',
                        mb: 1,
                    }}
                >
                {display_explanation(explanation)}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <IconButton onClick={handleExpandClick}>
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    <IconButton onClick={handleFullscreenClick}>
                        <FullscreenIcon />
                    </IconButton>
                </Box>
            </Paper>)}
            <Dialog open={fullscreen} onClose={handleCloseFullscreen} maxWidth="md" fullWidth>
                <DialogTitle>
                    <IconButton onClick={handleCloseFullscreen} sx={{ position: 'absolute', left: 8, top: 8 }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        {display_explanation(explanation)}
                    </Typography>
                </DialogContent>
            </Dialog>
            {currentQuestion && (
                <>
                    <Typography variant="h6">תרגיל {lesson.questions.length}</Typography>
                    <Question data={currentQuestion} onAnswer={submit_response} feedback={feedback} lessonState={lesson.state} responses={responses} setResponses={setResponses} initValue="" qKey={currentQuestion.id}/>
                    {lesson.state === "get_feedback" && (
                        <Box sx={{ position: 'relative', textAlign: 'right', mt: 1 }}>
                            <Paper elevation={3} sx={{ p: 1, display: 'inline-block' }}>
                                <Typography variant="body2">בודק{dots}</Typography>
                            </Paper>
                        </Box>
                    )}
                    {lesson.state === "get_flow" && !flowSuggestion?.uiLevel && (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                            <Button variant="contained" color="primary" onClick={() => { dispatch(setLessonState("get_question")) }}>
                                השאלה הבאה
                            </Button>
                            <Button variant="contained" color="secondary" onClick={() => { navigate('/dashboard') }}>
                                סיום
                            </Button>
                        </Box>
                    )}
                </>
            )}
            {outOfQuestions === 2 && (
                <Box>
                    <Typography>לא נמצאו שאלות</Typography>
                    <IconButton>
                        <EmbarrassedSmileyIcon />
                    </IconButton>
                </Box>
            )}
            {lesson.state === "get_flow" && flowSuggestion?.uiLevel==1 && (
                    <Box>
                        <TopicTransitionCard
                            title={flowSuggestion.type}
                            content="פתרת את כל השאלות בנושא זה בהצלחה. עכשיו נעבור לנושא הבא."
                            buttonText="התחל נושא חדש"
                            onClick={flowSuggestion.action}
                        />                        
                        <Typography>הצעת FLOW - TESTING VIEW</Typography>
                        <Typography>סוג הצעה: {flowSuggestion.type}</Typography>
                        <Typography>נושא:{flowSuggestion.topic}</Typography>
                    </Box>
                )
            }
            {
                lesson.state == "get_flow" && lessonEnd && (
                    <Box>
                        <Typography>שיעור הסתיים</Typography>
                    </Box>
                )
            }

    <div>
      <p>Total running time: {totalRunningTime.toFixed(2)} minutes</p>
      <p>Total questions: {lesson.questions.length}</p>
      {lesson.questions.map((q, i) => {
        return q.feedback?.normalGrade && <p key={i}>ציון מס' {i+1}: {q.feedback.normalGrade}</p>
      })}
      <button onClick={handleStartBreak}>Start Break</button>
      <button onClick={handleResumeLesson}>Resume Lesson</button>
    </div>

        </Box>
    );
};

export default Lesson;
