
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children, redirectTo }) => {
  const user = useSelector((state) => state.user);
  const students = useSelector((state) => state.students);
  const location = useLocation();
  const authenticaedEmail = user?.data?.login?.authenticatedEmail
  const onboardingState = user?.data?.onboarding?.state
  const plan = user?.plan
  const dueDate = user?.plan?.dueDate

  if (!authenticaedEmail) {
    return <Navigate to="/email_auth" state={{ from: location }} />;
  }

 if (['/user_onboarding', '/select_plan', '/payment', '/email_auth'].includes(redirectTo) && onboardingState === 'completed') {
    return <Navigate to="/dashboard" state={{ from: location }} />;
 }

 console.log('redirectTo', redirectTo);
 console.log(onboardingState)
 if ((!(['/user_onboarding', '/add_student', '/select_plan', '/payment', '/email_auth'].includes(redirectTo))) && onboardingState !== 'completed') {
  console.log("I want to  redirect to user_onboarding")
  console.log(onboardingState !== 'completed' ? 1:2)
    return <Navigate to="/user_onboarding" state={{ from: location }} />;
}

  if (redirectTo === '/select_plan') {
    if (students.length === 0) 
      return <Navigate to="/add_student" state={{ from: location }} />;
  }

  if (redirectTo === '/payment') {
    if (students.length === 0) 
      return <Navigate to="/add_student" state={{ from: location }} />;
    if (!plan)
      return <Navigate to="/select_plan" state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;
