import React, { useState, useRef, useCallback, useEffect, useImperativeHandle, forwardRef } from 'react';
import { addStyles, EditableMathField } from 'react-mathquill';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CalculatorIcon from '@mui/icons-material/Calculate';
import CalculatorModal from './CalculatorModal'; // Ensure this path is correct

// Add MathQuill styles
addStyles();

const MyMathInput = forwardRef((props, ref) => {
  const { feedback } = props;
  const mathQuillInstances = useRef({});
  const [mathLines, setMathLines] = useState([[{ id: 'field1', type: 'neutral', latex: '' }]]);
  const [focusId, setFocusId] = useState(null);
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);

  const isHebrew = (char) => /[\u0590-\u05FF]/.test(char);
  const isMath = (char) => /[A-Za-z0-9+\-*\/=<>^(){}[\]|\\.,:;_&%$#!?'"`~@Α-Ωα-ω]/.test(char);

  useImperativeHandle(ref, () => ({
    getFieldValue: () => {
      return mathLines.map(line => line.map(chunk => chunk.latex).join(' ')).join('\n');
    }
  }));

  const handleChange = useCallback((lineIndex, chunk, index, mathField) => {
    const newLatex = mathField.latex();
    mathQuillInstances.current[chunk.id] = mathField;
    const addedChar = mathField.latex().slice(-1);

    setMathLines((prevLines) => {
      const newChunks = [...prevLines[lineIndex]];
      const newLines = [...prevLines];

      let newType = null;
      if (isHebrew(addedChar)) {
        newType = 'hebrew';
      } else if (isMath(addedChar)) {
        newType = 'latex';
      }

      if (chunk.type === 'neutral' || mathField.latex().length === 1) {
        newChunks[index] = { ...chunk, type: newType };
      } else if (newType && chunk.type !== newType) {
        const field_id = 'f' + Math.floor(Math.random() * 10000);
        const newChunk = { id: field_id, type: newType, latex: addedChar };
        const updatedChunk = { ...chunk, latex: mathField.latex().slice(0, -1) };

        newChunks.splice(index, 1, updatedChunk);
        newChunks.splice(index + 1, 0, newChunk);

        setTimeout(() => {
          if (mathQuillInstances.current[chunk.id]) {
            mathQuillInstances.current[chunk.id].latex(updatedChunk.latex);
          }
          handleFocus(field_id);
        }, 0);
      } else {
        newChunks[index] = { ...chunk, latex: newLatex };
      }
      newLines[lineIndex] = newChunks;
      return newLines;
    });
  }, []);

  const handleFocus = (id) => {
    setFocusId(id);
  };

  useEffect(() => {
    // Reset state when the key changes
    setMathLines([[{ id: 'field1', type: 'neutral', latex: '' }]]);
    console.log('Math component rendered'); // Log when Math component is rendered
  }, [props.key]);


  useEffect(() => {
    if (focusId && mathQuillInstances.current[focusId]) {
      mathQuillInstances.current[focusId].focus();
    }
  }, [focusId]);

  const handleKeyDown = (e, lineIndex, chunk, index) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const newLine = [{ id: `field${Date.now()}`, type: 'neutral', latex: '' }];
      setMathLines((prevLines) => {
        const oldLines = [...prevLines];
        const newLines = [
          ...oldLines.slice(0, lineIndex + 1),
          newLine,
          ...oldLines.slice(lineIndex + 1)
        ];
        return newLines;
      });

      setTimeout(() => {
        handleFocus(newLine[0].id);
      }, 0);
    } else if (e.key === 'Backspace') {
      e.preventDefault();
      if (chunk.latex.length === 0 && lineIndex > 0) {
        setMathLines((prevLines) => {
          const newLines = [
            ...prevLines.slice(0, lineIndex),
            ...prevLines.slice(lineIndex + 1)
          ];
          setTimeout(() => {
            const prevId = newLines[lineIndex - 1][newLines[lineIndex - 1].length - 1].id;
            handleFocus(prevId);
          }, 0);
          return newLines;
        });
      }
    }
  };

  const handleCalculatorInsert = (latex) => {
    setMathLines((prevLines) => {
      const newLines = [...prevLines];
      if (!focusId) return newLines; // If no focusId is set, do nothing

      const [lineIndex, chunkIndex] = focusId.split('-').map(Number);
      const chunk = newLines[lineIndex][chunkIndex];
      const mathField = mathQuillInstances.current[chunk.id];

      if (mathField) {
        mathField.cmd(latex); // Use cmd to insert LaTeX command
        setTimeout(() => {
          mathField.latex(mathField.latex())
          chunk.latex = mathField.latex(); // Update chunk latex
          mathField.focus();
        }, 0);
      }


      return newLines;
    });
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      border: '1px solid', 
      flexDirection: 'column', 
      justifyContent: 'flex-start', 
      padding: '10px', 
      borderRadius: '10px',
      direction: 'rtl' // Ensure the parent container is RTL
    }}>
      {mathLines.map((mathLine, lineIndex) => (
        <Box key={`line-${lineIndex}`} sx={{ display: 'flex', border: '0px solid', flexWrap: 'wrap', gap: 0, flexDirection: 'column' }}>
          {feedback && feedback[`<${lineIndex + 1}>`] && (
            <Typography sx={{ color: 'red', marginBottom: '5px' }}>
              {feedback[`<${lineIndex + 1}>`].text}
            </Typography>
          )}
          <Box
            key={`input-${lineIndex}`}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 0,
              border: (feedback && feedback[`mathline${lineIndex + 1}`]) ? '1px solid red' : 'none',
              borderRadius: '4px',
              padding: '5px',
            }}
          >
            {mathLine.map((chunk, index) => (
              <Box
                key={chunk.id}
                sx={{
                  direction: chunk.type === 'hebrew' ? 'rtl' : 'ltr',
                  outline: 'none',
                  border: 'none',
                  display: 'inline-flex',
                  alignItems: 'center',
                  padding: '0px',
                  margin: '0px'
                }}
              >
                <EditableMathField
                  style={{ border: 'none', outline: 'none' }}
                  latex={chunk.latex}
                  onChange={(mathField) => handleChange(lineIndex, chunk, index, mathField)}
                  onKeyDown={(e) => handleKeyDown(e, lineIndex, chunk, index)}
                  onFocus={() => handleFocus(`${lineIndex}-${index}`)}
                  mathquillDidMount={(mathField) => {
                    mathQuillInstances.current[chunk.id] = mathField;
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      ))}
      <IconButton onClick={() => setIsCalculatorOpen(true)} sx={{ alignSelf: 'center', marginTop: '10px' }}>
        <CalculatorIcon />
      </IconButton>
      <CalculatorModal
        open={isCalculatorOpen}
        onClose={() => setIsCalculatorOpen(false)}
        onInsert={handleCalculatorInsert}
      />
    </Box>
  );
});

export default MyMathInput;
