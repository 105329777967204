import { db,auth } from '../firebase';
import { getFirestore, collection, where, query, limit, getDocs } from "firebase/firestore";
 
const fetchUserDetails = async (email) => {
    try {
        const url = 'https://api.example.com/user-details';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        });
        const data = await response.json();
        const userDetails = data.user;
        console.log('User details:', userDetails);
        // Continue with the necessary logic for onboarding
    } catch (error) {
        console.error('Error fetching user details:', error);
        console.error("Returning mock result")
        return { status: 'onboarding' };
    }
}

async function getCurriculum(key) {
    // Reference to the curriculum collection
    const curriculumRef = collection(db, "curriculum");
  
    // Query to get the first document
    const q = query(curriculumRef, where("key","==",key), limit(1));
  
    // Execute the query
    const querySnapshot = await getDocs(q);
  
    // Iterate through the results and collect the data
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
  
    // Assuming you want to return the first document
    return data.length > 0 ? data[0] : null;
  }
  
  async function getExplanation(topicName) {
      const BATCH = "c4_v3";
      console.log("Reading explanation",BATCH,topicName)
      const explanationsRef = collection(db, "explanation");
      const q = query(explanationsRef, where("topic", "==", topicName), where("batch", "==", BATCH), limit(1));
      
      try {
          const questSnapshot = await getDocs(q);
          if (questSnapshot.empty) {
              return null;
          }
          console.log("Explanation found")
          
          const doc = questSnapshot.docs[0];
          const data = doc.data();
          return data?.content?.he || null;
      } catch (error) {
          console.error("Error fetching explanation:", error);
          return null;
      }
  }

  
    var readTopics = {}

    async function getTopic(topicName) {
        if (readTopics[topicName])
            return readTopics[topicName]

        // Reference to the topic collection
        const topicRef = collection(db, "topic");
        const tempBatch = "c4_v3"

        // Query to get the first document where name matches topicName
        const q = query(topicRef, where("name", "==", topicName), where("batch", "==", tempBatch), limit(1));

        // Execute the query
        const topicSnapshot = await getDocs(q);

        // Iterate through the results and collect the data
        const data = [];
        topicSnapshot.forEach((doc) => {
            data.push(doc.data());
            readTopics[topicName] = doc.data()
        });

        // Assuming you want to return the first document
        return data.length > 0 ? data[0] : null;
    }
    
    // TODO: remove this function when the question generation is fixed //
    function normalizeQuestionText(questionText) {
        if (Array.isArray(questionText)) {
            questionText = questionText.join(' ');
        }
        questionText = questionText.replace("\f","\\f")
      
        return questionText
    }

    async function getQuestions(user,lesson,topicName,maxItems) {
        // Reference to the topic collection
        const questionRef = collection(db, "question");
        const tempBatch = "c4_test_5"

        //topicName = topicName.replace(/([A-Z])/g, ' $1'); //TODO: remove spaces from db
        //console.log("Topic Name: ",topicName)
        // Query to get the first document where name matches topicName

        maxItems = 4 // TODO TEMP!!
        const q = query(questionRef, where("topic", "==", topicName), where("batch", "==", tempBatch), limit(maxItems));

        console.log("Qs:",tempBatch,topicName,maxItems)
      
        // Execute the query
        const questionSnapshot = await getDocs(q);
      
        // Iterate through the results and collect the data
        const data = [];
        questionSnapshot.forEach((doc) => {
            console.log(doc.id)
            let question_id = doc.id
            doc = doc.data()
            doc.id = question_id
            doc.topic = doc.topic.replace(/ /g, ''); //TODO: remove spaces from db
            doc.cases = doc.cases || 0
            doc.elo = doc.elo || 1500

            if (doc && doc.elements) // TODO: this denotes some mismatch in question generation
                doc.elements.forEach(element => {
                    if (element.content && element.content.text) {
                        element.content.text = normalizeQuestionText(element.content.text)
                    }
                    if (element.content && element.content.options) {
                        for (let i = 0; i < element.content.options.length; i++) {
                            element.content.options[i] = normalizeQuestionText(element.content.options[i])
                        }
                    }
                });

            doc.status = "q"
              data.push(doc);
        });
      
        // Assuming you want to return the first document
        return data;
      }
      
      const getRecommendedLessons = async () => {
        const fetchTopicDetails = async (name, siblingIndex = 0, depth = 0) => {
            if (depth > 5) return null;
    
            const topicDetails = await getTopic(name);
            const children = await Promise.all(
                topicDetails.children.map((childName, childIndex) =>
                    fetchTopicDetails(childName, childIndex, depth + 1)
                )
            );
    
            return {
                name,
                displayName: topicDetails.displayName['he'],
                progress: Math.floor(Math.random() * 100) + 1,
                nextSiblings: topicDetails.children.slice(siblingIndex + 1),
                children: children.filter(child => child !== null)
            };
        };
    
        try {
            const tempKey = "c4_v3";
            const curriculum = await getCurriculum(tempKey);
            console.log(curriculum);
    
            let recommendedTopics = curriculum.topics;
            console.log(recommendedTopics);
    
            const recommendedLessons = await Promise.all(
                recommendedTopics.map(async (topicId, index) => {
                    return fetchTopicDetails(topicId, index);
                })
            );
    
            return recommendedLessons.filter(topic => topic !== null);
        } catch (error) {
            console.error('Error fetching recommended lessons:', error);
            return [];
        }
    };
      
  
export { fetchUserDetails, getRecommendedLessons, getTopic, getCurriculum, getQuestions, getExplanation};