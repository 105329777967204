import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const selectTotalRunningTimeInMinutes = createSelector(
  [(state) => state.lesson],
  (lesson) => {
    if (!lesson.startTs) return 0;

    const now = Date.now();
    const totalBreaksDuration = lesson.breaksDuration + (lesson.breakStartTs ? now - lesson.breakStartTs : 0);
    const runningTime = now - lesson.startTs - totalBreaksDuration;
    return runningTime / 60000; // Convert milliseconds to minutes
  }
);

const prepareQuestion = (question) => {
    if (question?.elements?.length > 0) {
        question.elements.forEach((element) => {
            if (element.type === 'multichoice' && (question.allow_shuffle !== false)) {
                console.log("SHUFFLE!")
                let options = element.content.options;

                // Keep a reference to the original first option
                const originalFirstOption = options[0];

                // Shuffle the options array
                let shuffledOptions = options
                    .map((value) => ({ value, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ value }) => value);

                // Find the new index of the original first option
                const originalFirstOptionIndex = shuffledOptions.indexOf(originalFirstOption);

                // Update the options in the question
                element.content.options = shuffledOptions;

                // Log or return the index of the original first option
                console.log('Original first option is now at index:', originalFirstOptionIndex);
                question.correctResult = originalFirstOptionIndex+1; // Optionally return this index if needed
            }

            if (element.type === 'subItems' && Array.isArray(element.content.items)) {
                element.content.items.forEach((subItem) => {
                    if (subItem.elements?.length > 1 && subItem.elements[1].type === 'multichoice' && (question.allow_shuffle !== false)) {
                        let options = subItem.elements[1].content.options;

                        // Keep a reference to the original first option
                        const originalFirstOption = options[0];

                        // Shuffle the options array
                        let shuffledOptions = options
                            .map((value) => ({ value, sort: Math.random() }))
                            .sort((a, b) => a.sort - b.sort)
                            .map(({ value }) => value);

                        // Find the new index of the original first option
                        const originalFirstOptionIndex = shuffledOptions.indexOf(originalFirstOption);

                        // Update the options in the sub item
                        subItem.elements[1].content.options = shuffledOptions;

                        // Set the index of the original first option in the sub item correctResult property
                        subItem.correctResult = originalFirstOptionIndex+1;
                    }
                });
            }
        });
    }
    return question;
};

export const lessonSlice = createSlice({
    name: 'lesson',
    initialState: {
      id: null,
      state: 'fetch_questions', // init, fetch_questions, get_question, get_answer, get_feedback, get_flow
      questions: [],
      originalTopic: '',
      startTs: null,
      topicStack: [], // Topics which are being covered
      pendingTopics: [], // Topics to be covered
      nextTopic: null, // Candidate topic (before setting it)
      pendingQuestions: [],
      clearQuestion: true,
      breakStartTs: null, // Timestamp when a break starts
      breaksDuration: 0, // Total duration of all breaks in milliseconds
      stateBeforeBreak: null,
      save: false,
      update: false
    },
    reducers: {
      initLesson: (state, action) => {
        console.log(action.payload);
        state.questions = action.payload.questions;
        state.topicHistory = null;
        state.startTs = Date.now();
        state.breaksDuration = 0;
        state.breakStartTs = null;
        state.topicStack = action.payload.topics;
        state.pendingTopics = action.payload.pendingTopics;
        state.pendingQuestions = [];
        state.state = 'init';
        state.save = 'true';
      },
      nextLesson: (state, action) => {
        state.questions = [];
        state.startTs = Date.now();
        state.breaksDuration = 0;
        state.breakStartTs = null;
        state.state = 'get_question';
        state.save = 'true';
      },
      setLessonState: (state, action) => {
        state.state = action.payload;
      },
      addQuestion: (state, action) => {
        state.questions.push(action.payload);
        state.state = 'get_answer';
        state.update = 'true';
      },
      addTopic: (state, action) => {
        console.log("Adding topic to stack", action.payload)
        state.topicStack.push(action.payload);
        state.state = 'fetch_questions';
        state.pendingQuestions = [];
        state.save = 'true'
      },
      popTopic: (state) => {
        state.topicStack.pop();
        state.state = 'fetch_questions';
        state.pendingQuestions = [];
        state.save = 'true'
      },
      setLastQuestionFeedback: (state, action) => {
        console.log('setLastQuestionFeedback', action.payload);
        if (state.questions.length > 0) {
          state.questions = state.questions.map((question, index) => {
            if (index === state.questions.length - 1) {
              return {
                ...question,
                feedback: action.payload,
                status: 'f',
              };
            }
            return question;
          });
          state.state = 'get_flow';
        }
      },      
      setNextTopic: (state, action) => {
        state.nextTopic = action.payload;
      },
      setPendingTopics: (state, action) => {
        state.pendingTopics = action.payload;
      },
      activatePendingTopic: (state) => {
        state.topicStack = [state.pendingTopics.shift()];
        state.state = 'fetch_questions';
        state.save = 'true';
      },
      addPendingQuestions: (state, action) => {
        state.pendingQuestions = [...state.pendingQuestions, ...action.payload];
        state.state = 'get_question';
      },
      activatePendingQuestion: (state) => {
        let question = state.pendingQuestions.shift();
        if (question) {
          question = prepareQuestion(question);
          state.questions.push({
            ...question
          });
          state.clearQuestion = true;
          state.state = 'get_answer';
          state.save = 'true';
        }
      },
      setClearQuestion: (state, action) => {
        state.clearQuestion = action.payload;
      },
      // New reducers for break management
      startBreak: (state) => {
        state.breakStartTs = Date.now();
        state.stateBeforeBreak = state.state
        state.state = 'break';
      },
      resumeLesson: (state) => {
        if (state.breakStartTs) {
          state.breaksDuration += Date.now() - state.breakStartTs;
          state.breakStartTs = null;
          state.state = state.stateBeforeBreak;
        }
      },
      setLessonId: (state,action) => {
        console.log("In setLessonId", action.payload)
        state.id = action.payload;
      },
      resetSave: (state,action) => {
        state.save = action.payload?.save || false;
        state.update = action.payload?.update || false;
      }
    },
  });

  export const {
    initLesson,
    nextLesson,
    setLessonState,
    addQuestion,
    addTopic,
    popTopic,
    setLastQuestionFeedback,
    setNextTopic,
    setPendingTopics,
    activatePendingTopic,
    addPendingQuestions,
    activatePendingQuestion,
    setClearQuestion,
    startBreak,
    resumeLesson,
    setLessonId,
    resetSave
  } = lessonSlice.actions;
  
export default lessonSlice.reducer;
