import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from './firebase';
import { setUser, clearUser } from './features/User/userSlice';
import { fetchStudents } from './features/Student/studentSlice.js';
import HomePage from './features/HomePage/HomePage';
import UserOnboarding from './features/UserOnboarding/UserOnboarding';
import Dashboard from './features/Dashboard/Dashboard';
import About from './features/Docs/About.jsx';
import Contact from './features/Docs/Contact.jsx';
import Terms from './features/Docs/Terms.jsx';
import PrivacyPolicy from './features/Docs/PrivacyPolicy.jsx';
import NotFoundPage from './features/NotFoundPage/NotFoundPage';
import SignUp from './features/SignUp/SignUp';
import SignIn from './features/SignIn/SignIn';
import ForgotPassword from './features/ForgotPassword/ForgotPassword';
import EmailAuth from './features/EmailAuth/EmailAuth.jsx';
import theme from './theme';
import Lesson from './features/Lesson/Lesson';
import Layout from './features/Layout/Layout';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import PlanSelection from './features/PlanSelection/PlanSelection';
import AddStudent from './features/AddStudent/AddStudent';
import Student from './features/Student/Student';
import Payment from './features/Payment/Payment';
import Account from './features/Account/Account';
import FeedbackForm from './features/FeedbackForm/FeedbackForm';
import TestMathInput from './features/TestMathInput';
import TestTracking from './features/TestTracking';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((userCredentials) => {
      if (userCredentials) {
        console.log(user)
        if (userCredentials.emailVerified) {
          dispatch(setUser({email:user.email, displayName:user.displayName}));
          dispatch(fetchStudents({userId:user.id}));
        } else {
          auth.signOut();
          dispatch(clearUser());
        }
      } else {
        dispatch(clearUser());
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [dispatch]);

  if (loading) {
    return (
      <div className="loading">
        <img src="/path/to/loading.gif" alt="Loading..." />
      </div>
    );
  }

  const getDefaultUserPage = () => {   
    if (!user.plan) {
        return <PlanSelection />;
    } else if (user.students.length === 0) {
        return <AddStudent />;
    } else {
        return <Dashboard />;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div>
          {user.id ? (
            <Routes>
              <Route path="/email_auth" element={<ProtectedRoute redirectTo="/email_auth"><Layout ><EmailAuth /></Layout></ProtectedRoute>} />
              <Route path="/about" element={<Layout><About /></Layout>} />
              <Route path="/contact" element={<Layout><Contact /></Layout>} />
              <Route path="/terms" element={<Layout><Terms /></Layout>} />
              <Route path="/privacy_policy" element={<Layout><PrivacyPolicy /></Layout>} />
              <Route path="/feedback" element={<Layout><FeedbackForm /></Layout>} />
              <Route path="/dashboard" element={<ProtectedRoute redirectTo="/dashboard"><Layout><Dashboard /></Layout></ProtectedRoute>} />
              <Route path="/user_onboarding" element={<ProtectedRoute redirectTo="/user_onboarding"><Layout><UserOnboarding /></Layout></ProtectedRoute>} />
              <Route path="/select_plan" element={<ProtectedRoute redirectTo="/select_plan"><Layout><PlanSelection /></Layout></ProtectedRoute>} />
              <Route path="/add_student" element={<ProtectedRoute redirectTo="/add_student"><Layout><AddStudent /></Layout></ProtectedRoute>} />
              <Route path="/payment" element={<ProtectedRoute redirectTo="/payment"><Layout><Payment /></Layout></ProtectedRoute>} />
              <Route path="/lesson" element={<ProtectedRoute redirectTo="/lesson"><Layout><Lesson /></Layout></ProtectedRoute>} />
              <Route path="/account" element={<ProtectedRoute redirectTo="/account"><Layout><Account /></Layout></ProtectedRoute>} />
              <Route path="/students" element={<ProtectedRoute redirectTo="/students"><Layout><Student /></Layout></ProtectedRoute>} />
              <Route path="/forgot_password" element={<ProtectedRoute><Layout><ForgotPassword /></Layout></ProtectedRoute>} />
              <Route path="/reset_password" element={<ProtectedRoute><Layout><resetPassword /></Layout></ProtectedRoute>} />
              <Route path="/test" element={<ProtectedRoute><Layout><TestMathInput /></Layout></ProtectedRoute>} />
              <Route path="/test_analytics" element={<ProtectedRoute><Layout><TestTracking /></Layout></ProtectedRoute>} />
              <Route path="/payment_success" element={<div>Hi, good payment :-)</div>} />
              <Route path="/payment_failure" element={<div>Hi, payment failed :-(</div>} />
              <Route path="*" element={<ProtectedRoute><Layout>{getDefaultUserPage()}</Layout></ProtectedRoute>} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/payment_success" element={<div>Hi, good payment 2:-)</div>} />
              <Route path="/payment_failure" element={<div>Hi, payment failed 2:-(</div>} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>)}
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
