import React, { useState } from 'react';
import { Box, Button, Container, TextField, Typography, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { auth } from '../../firebase';
import GoogleSignIn from '../GoogleSignIn/GoogleSignIn';
import { fetchUserData } from '../User/userSlice';
import { useNavigate } from 'react-router-dom';

const SignUpContainer = styled(Container)(({ theme }) => ({
  direction: 'rtl',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  backgroundColor: theme.palette.background.paper,
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  backgroundColor: theme.palette.background.default,
  width: '100%',
  maxWidth: '400px',
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (submitted) {
      setSubmitted(false);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (submitted) {
      setSubmitted(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    const isEmailValid = validateEmail(email);
    const isPasswordValid = validatePassword(password);

    setEmailError(!isEmailValid);
    setPasswordError(!isPasswordValid);

    if (isEmailValid && isPasswordValid) {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        dispatch(fetchUserData({ email, method: 'email', allowCreate: true, allowFetch: false }))
          .unwrap()
          .then(async (result) => {
            if (result.created) {
              await sendEmailVerification(user);
              navigate('/email_auth');
            } else {
              console.error('User creation failed');
            }
          })
          .catch((error) => {
            console.error('Failed to create user:', error);
          });

      } catch (error) {
        alert(error.message);
      }
    }
  };

  return (
    <SignUpContainer>
      <FormContainer>
        <Typography variant="h5" gutterBottom>
          הרשמה
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="אימייל"
            variant="outlined"
            margin="normal"
            value={email}
            onChange={handleEmailChange}
            error={submitted && emailError}
            helperText={submitted && emailError ? 'אימייל לא תקין' : ''}
            InputLabelProps={{
              style: { transformOrigin: 'top right', right: 28 },
            }}
            InputProps={{
              sx: { textAlign: 'right', '& .MuiOutlinedInput-notchedOutline': { textAlign: 'right' } },
            }}
          />
          <TextField
            fullWidth
            label="סיסמה"
            type="password"
            variant="outlined"
            margin="normal"
            value={password}
            onChange={handlePasswordChange}
            error={submitted && passwordError}
            helperText={submitted && passwordError ? 'הסיסמה חייבת להכיל לפחות 6 תווים' : ''}
            InputLabelProps={{
              style: { transformOrigin: 'top right', right: 28 },
            }}
            InputProps={{
              sx: { textAlign: 'right', '& .MuiOutlinedInput-notchedOutline': { textAlign: 'right' } },
            }}
          />
          <SubmitButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={email.length < 6 || password.length < 6}
          >
            הירשם
          </SubmitButton>
        </form>
        <Divider sx={{ width: '100%', margin: '16px 0' }} />
        <GoogleSignIn />
      </FormContainer>
    </SignUpContainer>
  );
};

export default SignUp;
