import React from 'react';
import { Box, Typography } from '@mui/material';

function HomeFooter() {
  return (
    <Box sx={{ bgcolor: 'text.secondary', color: 'white', p: 3, mt: 5 }}>
      <Typography variant="body2" align="center">
        כל הזכויות שמורות © 2024 מורה למתמטיקה
      </Typography>
    </Box>
  );
}

export default HomeFooter;
