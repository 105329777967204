import React from 'react';
import { Box } from '@mui/material';
import { InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';

const escapeUnescapedBackslashes = (str) => {
  // Replace unescaped backslashes with double backslashes
  return str.replace(/(?<!\\)\\(?!\\)/g, '\\\\');
};

export const chunkEquationAndText = (text, lineIndex) => {
  if (Array.isArray(text)) {
    text = text[0]
    //TODO: this is due to data creation mismatch
  }
  text = text.replace(/\f/g, '\\f')
  
  const parts = text.split(/(~[^~]*~)/g);
  return (
    <Box key={lineIndex} sx={{ display: 'inline', direction: 'rtl' }}>
      {parts.map((part, partIndex) => {
        if (part.startsWith('~')) {
          const equation = part.slice(1, -1);
          return (
            <Box key={`${lineIndex}-${partIndex}`} sx={{ display: 'inline-block', direction: 'ltr' }}>
              <InlineMath>{equation}</InlineMath>
            </Box>
          );
        } else {
          const hasHebrewChars = /[א-ת]/.test(part);
          return (
            <Box key={`${lineIndex}-${partIndex}`} sx={{ display: 'inline', direction: hasHebrewChars ? 'rtl' : 'ltr' }}>
              {part}
            </Box>
          );
        }
      })}
    </Box>
  );
};
