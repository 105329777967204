import React from 'react';
import TextPage from '../TextPage/TextPage';

// תנאי שימוש
const termsAndConditionsContent = {
  title: "תנאי שימוש",
  introduction: "ברוכים הבאים ל- פיתגו! אתרנו, pitago.co.il, מספק שירותי SaaS מבוססי AI בתחום המתמטיקה. השימוש באתר ובשירותים כפוף לתנאי השימוש המפורטים להלן.",
  topics: [
    {
      title: "1. קבלה ותנאים כלליים",
      subtopics: [
        {
          title: "1.1",
          text: "השימוש באתר ובשירותים מותנה בקבלת תנאי השימוש הללו. על ידי גישה ושימוש באתר או בשירותים, הנך מסכים לתנאים אלו."
        },
        {
          title: "1.2",
          text: "אנו שומרים את הזכות לעדכן או לשנות תנאי שימוש אלו בכל עת, ועליך לבדוק אותם מעת לעת."
        }
      ]
    },
    {
      title: "2. שירותים ותוכן",
      subtopics: [
        {
          title: "2.1",
          text: "השירותים מסופקים כפי שהם ('As Is') וללא אחריות מכל סוג שהוא, כולל דיוק התכנים ופעילותם התקינה."
        },
        {
          title: "2.2",
          text: "השירותים כוללים פתרון בעיות מתמטיות ובדיקתן אוטומטית על ידי AI. יש להימנע מהעלאת תכנים לא הולמים או בלתי חוקיים."
        }
      ]
    },
    {
      title: "3. אחריות והגבלת אחריות",
      subtopics: [
        {
          title: "3.1",
          text: "אנו לא נישא באחריות על טעויות או שגיאות שייעשו על ידי מערכת ה-AI. השירותים אינם מחויבים לכסות את כל תכנית הלימודים, אף על פי שאנו עוקבים בעיקרון."
        },
        {
          title: "3.2",
          text: "שירותים של צד ג' עשויים להיות בלתי זמינים מפעם לפעם, מה שעלול לפגוע בזמינות השירותים שלנו."
        }
      ]
    },
    {
      title: "4. תשלום וביטולים",
      subtopics: [
        {
          title: "4.1",
          text: "השירותים ניתנים על בסיס מנוי חודשי או שנתי. אין אפשרות לביטול התשלום החודשי או השנתי במהלך תקופת המנוי."
        },
        {
          title: "4.2",
          text: "במידה והמנוי מבוטל, החשבון יישאר פעיל עד למועד פקיעת תשלום הנוכחי."
        }
      ]
    },
    {
      title: "5. קניין רוחני",
      subtopics: [
        {
          title: "5.1",
          text: "כל הזכויות בשירות ובתוכן שייכות ל-פיתגו או לצדדים שלישיים עימם יש לנו הסכמים."
        },
        {
          title: "5.2",
          text: "אין לשכפל, להפיץ, או להשתמש בתכנים ללא אישור מראש ובכתב מ-פיתגו."
        }
      ]
    },
    {
      title: "6. פרטיות",
      subtopics: [
        {
          title: "6.1",
          text: "אנו מחויבים לשמירה על פרטיות המידע שלך בהתאם למדיניות הפרטיות שלנו, המפורטת באתר."
        }
      ]
    },
    {
      title: "7. סיום חשבון",
      subtopics: [
        {
          title: "7.1",
          text: "אנו שומרים את הזכות לסיים או להשעות את חשבונך בכל עת אם תפר תנאים אלו או אם תימצא פעילות חשודה או בלתי הולמת."
        },
        {
          title: "7.2",
          text: "סיום חשבון לא ישחרר את המשתמש מהחובה לשלם את כל החיובים שנצברו."
        }
      ]
    },
    {
      title: "8. חוק וסמכות שיפוט",
      subtopics: [
        {
          title: "8.1",
          text: "תנאים אלו יפורשו וינוהלו בהתאם לחוקי מדינת ישראל. כל מחלוקת שתתעורר תהיה בסמכות השיפוט הבלעדית של בתי המשפט בישראל."
        }
      ]
    }
  ]
};

const App = () => {
  return <TextPage {...termsAndConditionsContent} />;
};

export default App;
