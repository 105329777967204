import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, MenuItem, FormControl, InputLabel, Select, Rating, Snackbar, Alert, Fade } from '@mui/material';
import { functions, httpsCallable } from '../../firebase'; // Ensure you have configured Firestore in firebase.js

const FeedbackFormDialog = ({ open, onClose }) => {
  const [feedbackType, setFeedbackType] = React.useState('');
  const [feedback, setFeedback] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [userName, setUserName] = React.useState('');
  const [pageUrl] = React.useState(window.location.href); // Do not allow editing
  const [rating, setRating] = React.useState(0);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');

  const handleSubmit = async () => {
    if (!feedback.trim()) {
      setSnackbarMessage('שדה המשוב לא יכול להיות ריק');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const saveFeedback = httpsCallable(functions, 'saveFeedback');
    try {
      const result = await saveFeedback({ feedbackType, feedback, email, userName, pageUrl, rating });
      if (result.data.success) {
        setSnackbarMessage('המשוב נשלח בהצלחה');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setFeedbackType('');
        setFeedback('');
        setEmail('');
        setUserName('');
        setRating(0);
        onClose();
      }
    } catch (error) {
      console.error('שגיאה בשליחת המשוב:', error);
      setSnackbarMessage('שגיאה בשליחת המשוב');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>שלח משוב</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>סוג המשוב</InputLabel>
            <Select
              value={feedbackType}
              onChange={(e) => setFeedbackType(e.target.value)}
            >
              <MenuItem value="Bug Report">דיווח על תקלה</MenuItem>
              <MenuItem value="Feature Request">בקשת תכונה</MenuItem>
              <MenuItem value="General Feedback">משוב כללי</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="משוב"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            required
          />
          <TextField
            label="אימייל (לא חובה)"
            variant="outlined"
            fullWidth
            margin="normal"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="שם (לא חובה)"
            variant="outlined"
            fullWidth
            margin="normal"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <TextField
            label="כתובת העמוד (לא חובה)"
            variant="outlined"
            fullWidth
            margin="normal"
            value={pageUrl}
            disabled
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>דרג</InputLabel>
            <Rating
              name="rating"
              value={rating}
              onChange={(e, newValue) => setRating(newValue)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            ביטול
          </Button>
          <Button onClick={handleSubmit} color="primary">
            שלח
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={5000} 
        onClose={() => setSnackbarOpen(false)}
        TransitionComponent={Fade}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbarOpen(false)} 
          severity={snackbarSeverity} 
          sx={{ width: '100%', '& .MuiAlert-action': { marginRight: 'auto', paddingLeft: '16px' } }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default FeedbackFormDialog;
