import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Typography, Box, Divider, List, ListItem, IconButton, Button, ListItemText, FormControlLabel, Checkbox } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useNavigate, useLocation } from 'react-router-dom';

const Account = () => {
  const location = useLocation();
  const students = useSelector(state => state.students.students);
  const [autoRenewal, setAutoRenewal] = useState(true);
  const [sound, setSound] = useState(false);
  const navigate = useNavigate();

  const user = useSelector(state => state.user);
  console.log(user.data.email);

  // Create RTL theme
  const theme = createTheme({
    direction: 'rtl',
    typography: {
      fontFamily: 'Roboto, Arial, sans-serif',
    },
  });

  const actions = {
    viewEmail: (data) => {
      console.log('Viewing email:', data);
    },
    changePassword: () => {
      console.log('Changing password');
    },
    editName: (data) => {
      console.log('Editing name:', data);
    },
    savePersonalInformation: () => {
      console.log('Saving personal information changes');
    },
    viewStudent: (data) => {
      console.log('Viewing student:', data);
    },
    editStudent: (data) => {
      console.log('Editing student:', data);
    },
    addStudent: () => {
      console.log('Adding a new student');
    },
    viewCurrentPlan: (data) => {
      console.log('Viewing current plan:', data);
    },
    changePlan: () => {
      console.log('Changing plan');
    },
    cancelSubscription: () => {
      console.log('Cancelling subscription');
    },
    editLanguage: (data) => {
      console.log('Editing language:', data);
    },
    savePreferences: () => {
      console.log('Saving preferences');
    },
    viewRecentActivity: () => {
      console.log('Viewing recent activity');
    },
    viewFAQ: () => {
      console.log('Viewing FAQ');
    },
    contactSupport: () => {
      console.log('Contacting support');
    },
    logout: () => {
      console.log('Logging out');
    }
  };

  const structure = [
    {
      category: "פרטי התחברות",
      items: [
        { title: "אימייל", action: "viewEmail", data: user.data.email },
        { title: "שם בעל החשבון", action: "editName", data: "John Doe" },
      ]
    },
    {
      category: "תלמידים",
      items: students.flatMap(student => [
        { title: student.name, data: student.class },
      ]).concat({ title: "נהל תלמידים", action: ()=>navigate("/students"), type: "button" })
    },
    {
      category: "פרטי תשלום ומנוי",
      items: [
        { title: "תוכנית נוכחית", action: "viewCurrentPlan", data: "" },
        { title: "שדרוג", action: "changePlan", type: "button" },
        { title: "חידוש אוטומטי", type: "checkbox", value: autoRenewal, setValue: setAutoRenewal },
      ]
    },
    {
      category: "העדפות",
      items: [
        { title: "צליל", type: "checkbox", value: sound, setValue: setSound }
      ]
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container sx={{flexDirection: "rtl"}}>
        {structure.map((section, index) => (
          <div key={index}>
            <Typography variant="h5" sx={{ textAlign: 'right'}}>{section.category}</Typography>
            <List>
              {section.items.map((item, idx) => (
                <ListItem key={idx} sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', m: 0, pr: 0, pb: 0.5, pt: 0.5 }}>
                  {item.type === "checkbox" ? (
                    <FormControlLabel
                      control={<Checkbox checked={item.value} onChange={(e) => item.setValue(e.target.checked)} sx={{ m: 0, p: 0 }} />}
                      label={item.title}
                      sx={{ m: 0, p: 0, textAlign: 'right' }}
                    />
                  ) : item.type !== "button" ? (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', m: 0, p: 0 }}>
                      <Typography sx={{ textAlign: 'right', m: 0, ml: 1, p: 0 }}>{item.title}:</Typography>
                      <Typography sx={{ textAlign: 'right', marginLeft: 1, m: 0, p: 0 }}>{item.data}</Typography>
                    </Box>
                  ) : (
                    <Button variant="contained" color="primary" onClick={item.action} sx={{ m: 0 }}>
                      {item.title}
                    </Button>
                  )}
                </ListItem>
              ))}
            </List>
            {index < structure.length - 1 && <Divider style={{ margin: '20px 0' }} />}
          </div>
        ))}
      </Container>
    </ThemeProvider>
  );
};

export default Account;
