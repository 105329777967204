import React from 'react';
import { Box, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../../firebase';
import { fetchUserData } from '../User/userSlice';
import { useNavigate } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import { styled } from '@mui/system';

const GoogleButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#4285F4',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#357ae8',
  },
}));

const GoogleSignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      if (user) {
        const email = user.email;
        dispatch(fetchUserData({ email, method: 'google', allowCreate: true, allowFetch: true }))
          .unwrap()
          .then((result) => {
            console.log('User data fetched:', result.userData);
            if (result.userData.status === 'onboarding') {
              navigate('/user_onboarding');
            } else {
              navigate('/dashboard');
            }
          })
          .catch((error) => {
            console.error('Failed to fetch user data:', error);
          });
      }
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  return (
    <Box sx={{ width: '100%', direction: 'ltr' }}>
      <GoogleButton
        fullWidth
        variant="contained"
        startIcon={<GoogleIcon />}
        onClick={handleGoogleSignIn}
      >
        התחבר עם גוגל
      </GoogleButton>
    </Box>
  );
};

export default GoogleSignIn;
