function getChartData (content) {
    let contentChartData = content.chartData
    const chartData = {
    labels: contentChartData.labels,
    datasets: [
      {
        data: contentChartData["data"],  // Use null for separating data ranges
        fill: false,
        borderColor: 'blue',
        tension: 0,  // Curved lines
      }
    ],
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: contentChartData["graph-title"],
        },
        tooltip: {
          mode: 'index',
          intersect: false,
        },
        legend: {
          display: false,
          position: 'top',
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: contentChartData["x-title"],
          },
        },
        y: {
          title: {
            display: true,
            text: contentChartData["y-title"],
          },
          min: contentChartData["min-y"],
          max: contentChartData["max-y"],
        },
      },
    },
  }
  return chartData
}

export { getChartData }