import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initLesson } from '../Lesson/lessonSlice';
import { updateStudent } from '../Student/studentSlice';
import Rewards from '../Rewards/Rewards';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  MenuItem,
  Menu,
  Button,
  IconButton,
  FormControlLabel,
  Switch
} from '@mui/material';
import setPage from '../Page/pageSlice';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import trackEvent from '../../utils/analyticsUtils';
import { getRecommendedLessons, getTopic } from '../../utils/dbUtils';
import { current } from '@reduxjs/toolkit';

// Create RTL cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

// Create RTL theme
const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
});

const Dashboard = () => {
  const curStudent = useSelector(state => state.students.students[state.students.active] || null);
  const curLesson = useSelector(state => state.lesson);
  const [search, setSearch] = useState('');
  const [lessonTopicDisplayName, setTopicDisplayName] = useState('');
  const [nextLesson, setNextLesson] = useState(''); 
  const [filter, setFilter] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [topics, setTopics] = useState(null);
  const [visibleTopics, setVisibleTopics] = useState(10);
  const [filteredTopics, setFilteredTopics] = useState(null);
  const [refreshFilter, setRefreshFilter] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!nextLesson && topics && curLesson.id!=null) {
      let myLesson = Object.values(topics).find(lesson => curLesson.name === 'fractionAsPartOfWhole');
      setNextLesson(myLesson);
    }
  }, [topics]);

  useEffect(() => {
    if (curLesson.id) {
      console.log(curLesson)
      getTopic(curLesson.topicStack[0]).then(topic => {
        console.log(topic)
        setTopicDisplayName(topic?.displayName?.he);
      });
    }
  }, [curLesson,dispatch]);

  useEffect(() => {
    if (refreshFilter) {
      applyFilter();
      setRefreshFilter(false);
    }

    const fetchRecommendedLessons = async () => {
      try {
        let lessons = await getRecommendedLessons();
        setTopics(lessons);
        setFilteredTopics(lessons); // Initialize filteredTopics with fetched lessons
      } catch (error) {
        console.error('Error fetching recommended lessons:', error);
      }
    };

    if (topics == null) fetchRecommendedLessons();
  }, [refreshFilter]);

  const filterTopicsRecursively = (topics) => {
    return topics
      .filter((topic) => topic.displayName.includes(search) && (showCompleted || topic.progress !== '100%'))
      .map((topic) => ({
        ...topic,
        children: topic.children ? filterTopicsRecursively(topic.children) : [],
      }));
  };

  const applyFilter = () => {
    if (topics) {
      const myFilteredTopics = filterTopicsRecursively(topics);
      setFilteredTopics(myFilteredTopics);
      // Track filter event
      trackEvent('Filter Applied', {
        search,
        showCompleted
      });
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setRefreshFilter(true);
    // Track search event
    trackEvent('Search Changed', {
      search: event.target.value
    });
  };

  const handleShowCompletedChange = (event) => {
    setShowCompleted(event.target.checked);
    setRefreshFilter(true);
    // Track show completed change event
    trackEvent('Show Completed Changed', {
      showCompleted: event.target.checked
    });
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.checked);
    // Track filter change event
    trackEvent('Filter Toggled', {
      filter: event.target.checked
    });
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    // Track menu open event
    trackEvent('Menu Opened');
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // Track menu close event
    trackEvent('Menu Closed');
  };

  const handleShowMore = () => {
    setVisibleTopics((prev) => prev + 10);
    // Track show more event
    trackEvent('Show More Clicked');
  };

  const handleLessonClick = async (lesson) => {
    // Function to find the first child recursively
    const findFirstChildRecursively = (topic) => {
      if (topic.children && topic.children.length > 0) {
        return findFirstChildRecursively(topic.children[0]);
      }
      return topic;
    };
  
    // Find the actual topic to be used
    const actualTopic = findFirstChildRecursively(lesson);
  
    let lessonDetails = {
      questions: [],
      topics: [actualTopic.name],
      pendingTopics: actualTopic.nextSiblings || [],
      selectedTopic: curLesson.name // Store the originally selected topic
    };
  
    if (!(curLesson.name in curStudent.topics)) {
      console.log("Updating student topic")
      dispatch(updateStudent({
        id: curStudent.id,
        studentData: {
          topics: {
            ...curStudent.topics,
            [curLesson.name]: { elo: 1500, cases: 0 }
          }
        }
      }));
    }
  
    dispatch(initLesson(lessonDetails));
  
    navigate('/lesson');
    // Track lesson click event
    trackEvent('Lesson Clicked', {
      lessonName: curLesson.name
    });
  };

  const getTopicDisplayName = async (topicName) => {
    let topic = await getTopic(topicName);
    setTopicDisplayName(topic?.he?.displayName)
  }

const renderTopics = (topicsList, level = 0) => {
    return topicsList.slice(0, visibleTopics).map((topic, index) => (
      <Box onClick={() => handleLessonClick(topic)} key={index} sx={{ pl: level * 2, cursor: 'pointer' }}>
          <Typography variant="body1">{topic.displayName}</Typography>
        {topic.children && topic.children.length > 0 && (
          <Box sx={{ pl: 2 }} onClick={(e) => e.stopPropagation()}>
            {renderTopics(topic.children, level + 1)}
          </Box>
        )}
      </Box>
    ));
  };

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Container>
          <Grid container spacing={3}>
            {curLesson.id && lessonTopicDisplayName &&
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ padding: '20px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography variant="h6">{lessonTopicDisplayName}</Typography>
                  <Button variant="contained" color="primary" onClick={() => navigate('/lesson')}>המשך שיעור</Button>
                </Box>
              </Paper>
            </Grid>}
          </Grid>
          <Grid container spacing={3}>
            {nextLesson &&  
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ padding: '20px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography variant="h6">נושא הלמידה הבא: {nextLesson.displayName}</Typography>
                  <Button variant="contained" color="primary" onClick={() => handleLessonClick(nextLesson)}>התחל שיעור</Button>
                </Box>
              </Paper>
            </Grid>}
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ padding: '20px' }}>
                <TextField
                  fullWidth
                  label="חפש נושא"
                  value={search}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
                <FormControlLabel
                  control={<Switch checked={showCompleted} onChange={handleShowCompletedChange} />}
                  label="הצג נושאים שהושלמו"
                />
                <IconButton onClick={handleMenuOpen}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleMenuClose}>סינון לפי קטגוריה</MenuItem>
                  <MenuItem onClick={handleMenuClose}>סינון לפי תאריך</MenuItem>
                </Menu>
                {filteredTopics && (
                  <Box sx={{ mt: 2 }}>
                    {renderTopics(filteredTopics)}
                    {visibleTopics < filteredTopics.length && (
                      <Button onClick={handleShowMore}>עוד</Button>
                    )}
                  </Box>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Rewards />
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default Dashboard;
