import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { httpsCallable } from 'firebase/functions'; 
import { functions } from '../../firebase'; 

export const resolveStudentQuestion = createAction('student/resolveStudentQuestion');

// Create a new student
export const createStudent = createAsyncThunk(
  'student/createStudent',
  async (studentData, { rejectWithValue }) => {
    try {
      const addStudent = httpsCallable(functions, 'addStudent');
      const result = await addStudent(studentData);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Read students
export const fetchStudents = createAsyncThunk(
  'student/fetchStudents',
  async ({userId}, { rejectWithValue }) => {
    try {
      console.log("fetchStudents userId: ", userId);
      const getStudents = httpsCallable(functions, 'getStudents');
      const result = await getStudents({ userId });
      console.log("fetchStudents result: ", result.data)
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Update a student
export const updateStudent = createAsyncThunk(
  'student/updateStudent',
  async ({ id, studentData }, { rejectWithValue }) => {
    try {
      const modifyStudent = httpsCallable(functions, 'modifyStudent');
      const result = await modifyStudent({ id, ...studentData });
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Delete a student
export const deleteStudent = createAsyncThunk(
  'student/deleteStudent',
  async (id, { rejectWithValue }) => {
    try {
      const removeStudent = httpsCallable(functions, 'removeStudent');
      const result = await removeStudent({ id });
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const studentSlice = createSlice({
  name: 'student',
  initialState: {
    students: [],
    active: 0,
    status: 'idle',
    error: null,
  },
  reducers: {
    resolveStudentQuestion: (state, action) => {
      console.log("Resolving student!")

      const { studentId, topic, newElo, grade } = action.payload;
      const index = state.students.findIndex(student => student.id === studentId);
      if (index !== -1) {
        console.log("Student found!")
        if (!state.students.topics[topic]) {
          state.students[index].topics[topic] = { grades: [], elo: 0, cases: 0};
        }        
        state.students[index].topics[topic].elo = newElo;
        state.students[index].topics[topic].cases += 1;
        state.students[index].topics[topic].grades.push(grade)

      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Create student
      .addCase(createStudent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createStudent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.students.push(action.payload);
      })
      .addCase(createStudent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Fetch students
      .addCase(fetchStudents.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStudents.fulfilled, (state, action) => {
        console.log("!!")
        state.status = 'succeeded';
        state.students = action.payload;
        state.students.forEach(student => {
          if (!student.topics) {
            student.topics = [];
          }
        });
      })
      .addCase(fetchStudents.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Update student
      .addCase(updateStudent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateStudent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.students.findIndex(student => student.id === action.payload.id);
        if (index !== -1) {
          state.students[index] = action.payload;
        }
      })
      .addCase(updateStudent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Delete student
      .addCase(deleteStudent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteStudent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.students = state.students.filter(student => student.id !== action.payload.id);
      })
      .addCase(deleteStudent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Resolve student question
  },
});

export const { resolveStudentQuestion: resolveStudentQuestionAction } = studentSlice.actions;

export default studentSlice.reducer;
